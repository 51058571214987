import axios from 'axios';
import { FIREBASE_BASE_URL } from '../utils';

export namespace GuideAPI {

    export const getLearnersByGuideEmail = (props: { email: string }) => {
        return axios.get(`${FIREBASE_BASE_URL}/getStudentsByTeacherEmail`, {
            params: {
                teacherEmail: props.email
            }
        });
    }

    export const fetchMessages = (props: { threadId: string }) => {
        return axios.get(`${FIREBASE_BASE_URL}/fetchMessages`, {
            params: {
                threadId: props.threadId
            }
        });
    }

    export const getChatSummary = (props: { chat: string, name: string }) => {
        return axios.get(`${FIREBASE_BASE_URL}/getChatSummary`, {
            params: {
                chat: props.chat,
                name: props.name
            }
        });
    }

    export const fetchLearners = (props: { email: string }) => {
        return axios.get(`${FIREBASE_BASE_URL}/getStudentsByTeacherEmail`, {
            params: {
                email: props.email
            }
        });
    }

    export const getLearnerInfoById = (props: { id: number }) => {
        return axios.get(`${FIREBASE_BASE_URL}/getUserInfoById`, {
            params: {
                id: props.id.toString()
            }
        });
    }

    export const addLearnerToGuide = (props: { guideEmail: string, learnerEmail: string }) => {
        return axios.post(`${FIREBASE_BASE_URL}/addStudentToTeacher`, {
            teacherEmail: props.guideEmail.toLowerCase(),
            studentEmail: props.learnerEmail.toLowerCase(),
            isApproved: true
        });
    }

    export const deleteStudentFromTeacher = (props: { teacherEmail: string, studentEmail: string }) => {
        return axios.post(`${FIREBASE_BASE_URL}/deleteStudentFromTeacher`, {
            teacherEmail: props.teacherEmail,
            studentEmail: props.studentEmail,
        });
    }

    export const addGenericTeacherPromptToUser = (props: { userEmail: string, teacherPrompt: string }) => {
        return axios.post(`${FIREBASE_BASE_URL}/addTeacherPromptToUser`, {
            userEmail: props.userEmail,
            teacherPrompt: props.teacherPrompt
        });
    }

    export const addSpecificTeacherPromptToUser = (props: { threadId: string, teacherPrompt: string }) => {
        return axios.post(`${FIREBASE_BASE_URL}/addTeacherPromptToUserProject`, {
            threadId: props.threadId,
            teacherPrompt: props.teacherPrompt
        });
    }

}