import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
import { modalSlice } from "./slice";
import { useMemo } from "react";

// Create selectors
const selectModalLoading = (state: RootState) => state.modal.loading;
const selectModalState = (state: RootState) => state.modal.state;

export const useModal = () => {
    const dispatch = useDispatch();

    // Use specific selectors instead of selecting the entire state
    const loading = useSelector(selectModalLoading);
    const state = useSelector(selectModalState);

    // Memoize actions and thunks
    const actions = useMemo(
        () => bindActionCreators(modalSlice.actions, dispatch),
        [dispatch]
    );

    return {
        loading,
        state,
        actions,
    };
};
