import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../init/reduxInit';
import { Resource } from '../state';
import { useResources } from '../export';
import PanelHeader from '../../../panel/components/PanelHeader';
import { styles } from '../../../../../styling/styles';
import VideoPanel from './panels/VideoPanel';
import ArticlePanel from './panels/ArticlePanel';

const ResourcePanel: React.FC = () => {
    const resources = useResources()

    if (!resources.currentPage?.mainResource?.title && !resources.loading) {
        return <></>
    }

    const PanelContent: React.FC<PropsWithChildren> = ({ children }) => {
        return (
            <div className="p-4 flex flex-col h-full">
                <PanelHeader
                    onBack={() => resources.actions.previousPage()}
                    onForward={() => resources.actions.nextPage()}
                    backEnabled={resources.pageNumber < resources.resourcePages.length - 1}
                    forwardEnabled={resources.pageNumber > 0}
                />
                <div className='flex flex-col w-full h-full items-center space-y-16 mt-4'>
                    <div className='flex flex-col px-6'>
                        <h1>{resources.currentPage.title}</h1>
                        <p>{resources.currentPage.description}</p>
                    </div>
                    <div className='flex flex-col w-full h-full justify-center items-center pb-16'>
                    <div className="relative w-full px-4 flex justify-center">
                        <div className={`${styles.panelCard} flex flex-col px-8 py-12 bg-grey-100 -rotate-[1.5deg] w-full h-full justify-center absolute top-0 left-0`}>
                        </div>
                        <div className={`${styles.panelCard} flex flex-col px-8 py-12 bg-grey-100 w-full h-full justify-center absolute top-0 left-0`}>
                        </div>
                        <div className={`${styles.panelCard} flex flex-col w-full bg-white rotate-[1.5deg] h-full justify-center relative z-10`}>
                            {resources.loading ? (
                                <div className='flex flex-col w-full h-full justify-center p-4'>
                                    <div className="aspect-video flex w-full mt-4 shadow-sm hover:shadow-md bg-gray-200 rounded-lg animate-pulse"></div>
                                    <div className="py-8">
                                        <div className="h-6 bg-gray-200 rounded w-3/4 mb-2 animate-pulse"></div>
                                        <div className="h-4 bg-gray-200 rounded w-full animate-pulse"></div>
                                    </div>
                                </div>
                            ) : (
                                <div className='flex flex-col w-full h-full justify-center'>
                                    {children}
                                </div>
                            )}
                        </div>
                    </div>
                    </div>
                </div>
            </div>)
    }

    // Add this condition to check for highlighted resource
    if (resources.currentPage?.highlighted) {
        return (
            <PanelContent>
                {renderResourceContent(resources.currentPage.highlighted)}
            </PanelContent>
        );
    }

    switch (resources.currentPage?.mainResource?.type || "default") {
        case "video":
            return (
                <PanelContent>
                    <VideoPanel {...resources.currentPage.mainResource!} />
                </PanelContent>
            );
        case "article":
            return (
                <PanelContent>
                    <ArticlePanel {...resources.currentPage.mainResource!} />
                </PanelContent>
            );
        default:
            return (
                <PanelContent />
            );
    }
};

// Add this helper function to render the correct resource component
const renderResourceContent = (resource: Resource) => {
    switch (resource.type) {
        case "video":
            return <VideoPanel {...resource} />;
        case "article":
            return <ArticlePanel {...resource} />;
        default:
            return null;
    }
};

export default ResourcePanel;
