import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
import { authSlice } from "./slice";
import { AuthThunks } from "./thunks";
import { getCurrentUser } from "aws-amplify/auth";
import { useMemo } from "react";
import { initState } from "./state";

// Create selectors
const selectAuthState = (state: RootState) => state.auth;

export const useAuth = () => {
    const dispatch = useDispatch();

    // Use specific selector instead of selecting the entire state
    const state = useSelector(selectAuthState);

    // Memoize actions and thunks
    const actions = useMemo(
        () => bindActionCreators(authSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(AuthThunks, dispatch),
        [dispatch]
    );

    return { state, actions, thunks };
};

export const getUserInfo = async () => {
    logger.log("[AUTH] 🔄 getUserInfo");
    const { username, userId, signInDetails } = await getCurrentUser();
    return { username, userId, signInDetails };
};

export const isSignedIn = (getState: () => RootState): boolean => {
    try {
        return getState().auth.data.email !== initState.data.email;
    } catch (error) {
        logger.error("Error checking auth status:", error);
        return false;
    }
};