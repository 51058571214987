import axios from 'axios';
import { CARTESIA_API_BASE_URL } from '../components/ducks/utils';
import { PropsWithChildren } from 'react';

interface CartesiaConfig {
    apiKey: string;
    version: string;
}

let cartesiaConfig: CartesiaConfig | null = null;

export const CartesiaInit = ({ children }: PropsWithChildren<{}>) => {
    if (process.env.REACT_APP_CARTESIA_API_KEY && process.env.REACT_APP_CARTESIA_VERSION) {
        cartesiaConfig = {
            apiKey: process.env.REACT_APP_CARTESIA_API_KEY,
            version: process.env.REACT_APP_CARTESIA_VERSION,
        };

        // Set up axios defaults for Cartesia API calls
        axios.defaults.baseURL = CARTESIA_API_BASE_URL;
        axios.defaults.headers.common['X-API-Key'] = cartesiaConfig.apiKey;
        axios.defaults.headers.common['Cartesia-Version'] = cartesiaConfig.version;
        axios.defaults.headers.common['Content-Type'] = 'application/json';

        logger.info('Cartesia initialized successfully');
    } else {
        logger.warn('Cartesia API key or version is not defined');
    }

    return <>{children}</>;
};

export const getCartesiaConfig = (): CartesiaConfig => {
    if (!cartesiaConfig) {
        throw new Error('Cartesia has not been initialized. Ensure CartesiaInit is used properly.');
    }
    return cartesiaConfig;
};

export const isCartesiaInitialized = (): boolean => {
    return cartesiaConfig !== null;
};
