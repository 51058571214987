import React from 'react';
import { useUser } from '../export';

const TitleHeader: React.FC = () => {
    const user = useUser();

    return (
        <div className="w-full justify-center flex mb-8">
            <h1 className="text-gray-800">
                {`What are you curious about${user.state.data.name && `, ${user.state.data.name.split(" ")[0]}`}?`}
            </h1>
        </div>
    );
};

export default TitleHeader;
