import React from 'react';
import KanbanColumn from './Kanban/KanbanColumn';
import PanelHeader from '../../panel/components/PanelHeader';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSteps } from '../export';
import { Step } from '../state';

const StepsPanel: React.FC<{ inputSteps?: Step[] }> = ({ inputSteps }) => {
    const Steps = useSteps();

    return (
        <div className="p-4 flex flex-col h-full">
            <PanelHeader closeable={!inputSteps} />
            <div className='flex flex-col w-full h-full overflow-y-auto space-y-8'>
                {/* <DragDropContext onDragEnd={() => { }}> */}
                {inputSteps ? (
                    <>
                        {(inputSteps.some(step => step.status === "DONE") || inputSteps.some(step => step.status === "DOING")) && <KanbanColumn status={'DONE'} isDraggable={false} isDragging={false} inputSteps={inputSteps} />}
                        {(inputSteps.some(step => step.status === "TODO") || inputSteps.some(step => step.status === "DOING")) && <KanbanColumn status={'DOING'} isDraggable={false} isDragging={false} inputSteps={inputSteps} />}
                        <KanbanColumn status={'TODO'} isDraggable={false} isDragging={false} inputSteps={inputSteps} />
                    </>
                ) : (<>
                        {Steps.steps && (Steps.steps.some(step => step.status === "TODO") || Steps.steps.some(step => step.status === "DOING")) && <KanbanColumn status={'DOING'} isDraggable={false} isDragging={false} />}
                        <KanbanColumn status={'TODO'} isDraggable={false} isDragging={false} />
                    </>)}
                {/* </DragDropContext> */}
            </div>
        </div>
    );
};

export default StepsPanel;