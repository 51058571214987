import { Step } from "../../steps/state";

export type ProjectUploadState = {
    upload?: ProjectFile,
    stepToUpload?: Step,
    files: ProjectFile[],
    status: "ok" | "preparing" | "saving" | "sharing";
};

export type ProjectFile = {
    // file: File,
    step?: Step,
    bucket?: {
        type: string,
        name: string,
        uploadUrl: string,
        key: string
    }
}

export const initState: ProjectUploadState = {
    upload: undefined,
    stepToUpload: undefined,
    files: [],
    status: "ok",
};
