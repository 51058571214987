import { Image } from "../images/state";

export type ProjectInspirationsState = {
    inspirations: ProjectInspiration[];
    highlighted: number,
    loading: boolean;
};

export type ProjectInspiration = {
    title: string,
    description: string,
    image: Image
}

export const initState: ProjectInspirationsState = {
    inspirations: [],
    highlighted: -1,
    loading: false,
};
