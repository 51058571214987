import axios from 'axios';
import { FIREBASE_BASE_URL } from '../utils';

export namespace API {

    export const getUserInfo = (props: { email: string }) => {
        return axios.get(`${FIREBASE_BASE_URL}/getUserInfoByEmail`, {
            params: {
                email: props.email.toLowerCase()
            }
        })
    }

    export const createUser = (props: { awsId: string, name: string, email: string, role: string }) => {
        return axios.post(`${FIREBASE_BASE_URL}/addPblUserToDB`, {
            role: props.role,
            name: props.name,
            email: props.email.toLowerCase(),
            awsId: props.awsId
        })
    }

    export const sendNewUserSlackMessage = async (props: { learnerName: string, learnerEmail: string, teacherEmail: string }) => {
        const slackWebhookUrl = "https://hooks.slack.com/triggers/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";
      
        const teacherName = (await axios.get(`${FIREBASE_BASE_URL}/getUserDetailsFromEmail`, {
          params: {
            userEmail: props.teacherEmail.toLowerCase()
          }
        })).data.name;
      
        const messageParams = {
          learnerName: props.learnerName,
          learnerEmail: props.learnerEmail,
          teacherName: teacherName,
          teacherEmail: props.teacherEmail
        };
      
        return axios.post(`${FIREBASE_BASE_URL}/sendSlackMessage`, {
          slackWebhookUrl,
          messageParams
        }, {
          headers: {
            "Content-Type": "application/json"
          }
        });
      }
}
