import { AssistantThunks } from "../assistant/thunks";
import { panelSlice } from "../panel/slice";
import { toastSlice } from "../toast/slice";
import { Thunk } from "../utils";
import { widgetSlice } from "../widget/slice";
import { optionsSlice } from "./slice";
import { Option, OptionsState } from "./state";

export namespace OptionsThunks {

    export const addOptions: Thunk<any> = (args: any) => async (dispatch, getState) => {
        try {
            logger.log("[PROJECT INSPIRATION THUNK] 🔵 Add options operation started");
            dispatch(panelSlice.actions.setState('none'));
            dispatch(widgetSlice.actions.setState('options'));
            // logger.log(args)
            const potentialPaths = JSON.parse(args);
            if (potentialPaths.length > 0) {
                const mappedPotentialPaths: Option[] = potentialPaths.map((path: any) => ({
                    title: path,
                }));
                logger.log(`Found ${mappedPotentialPaths.length} options`);
                dispatch(optionsSlice.actions.setOptions(mappedPotentialPaths));
                dispatch(optionsSlice.actions.setLoading(false));
            } else {
                dispatch(optionsSlice.actions.setLoading(true));
            }
        } catch (error: any) {
            logger.log("[PROJECT INSPIRATION THUNK] 🔴 Error adding options", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to add the resource page.",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
            dispatch(panelSlice.actions.reset());
            dispatch(widgetSlice.actions.reset());
        } finally {
            logger.log("[PROJECT INSPIRATION THUNK] 🟢 Add options complete");
        }
    }

    export const chooseOptionThunk: Thunk<Option> = (option) => async (dispatch, getState) => {
        logger.log("[PROJECT THUNK] 🔵 Choosing option");

        try {
            dispatch(AssistantThunks.sendMessage(option.title))
            dispatch(panelSlice.actions.reset())
            dispatch(widgetSlice.actions.reset())
        } catch (error: any) {
            logger.error("[PROJECT THUNK] 🔴 Error choosing option", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to save inspirations",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            logger.log("[PROJECT THUNK] 🟢 Choose option completed");
        }
    };

}