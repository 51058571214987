import axios from "axios"
import ChatHistory from "../../utils/components/ducks/assistant/components/chat/History"
import ChatInput from "../../utils/components/ducks/assistant/components/chat/Input"
import Panel from "../../utils/components/ducks/panel/components/Panel"
import { usePanel } from "../../utils/components/ducks/panel/export"
import { useSession } from "../../utils/components/ducks/session/export"
import { Widget } from "../../utils/components/ducks/widget/components/Widget"
import Button from "../../utils/components/grapes/atoms/button/Button"
import Navbar from "../../utils/components/grapes/molecules/navbar"
import { FIREBASE_BASE_URL } from "../../utils/components/ducks/utils"
import { useWidget } from "../../utils/components/ducks/widget/export"
import WidgetOption from "../../utils/components/ducks/widget/components/WidgetOption"
import Suggestion from "../../utils/components/ducks/assistant/components/chat/Suggestion"
import { useAssistant } from "../../utils/components/ducks/assistant/export"
import Confetti from 'react-confetti';
import { useUpload } from "../../utils/components/ducks/project/progress-upload/export"
import { useEffect, useState } from "react"
import { useUser } from "../../utils/components/ducks/user/export"
import TitleHeader from "../../utils/components/ducks/user/components/TitleHeader"

const ChatPage = () => {
    const assistant = useAssistant()
    const upload = useUpload()
    const [showConfetti, setShowConfetti] = useState(false);

    useEffect(() => {
        if (upload.status === 'saving') {
            console.log("AMAAN upload.status", upload.status)
            setShowConfetti(true)
            setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
        }
    }, [upload.status])


    return (
        <>
            <main className={`relative flex flex-grow justify-center overflow-hidden pl-16 pr-8 pb-10 pt-2 gap-x-8 bg-white`}>
                {assistant.data.messages.length === 0 && (
                    <div className="absolute inset-0"></div>
                )}
                {showConfetti && <Confetti />}
                {/* Main content area */}
                <div className="flex-grow flex flex-col max-w-[50%] items-stretch justify-center relative z-10 transition-all duration-300 ease-in-out">
                    {/* Chat content area */}
                    {assistant.data.messages.length > 0 ?
                        <ChatHistory />
                        :
                        <TitleHeader />
                    }

                    {/* Widgets */}
                    <Widget />

                    {/* Blue box at the bottom left */}
                    <div
                        className="flex bg-white border-gray-100 border-4 rounded flex-col z-20"
                    >
                        <div className={`transition-all duration-300 ease-in-out ${assistant.suggestions && assistant.suggestions.length > 0 ? 'py-4 max-h-[300px]' : 'py-0 max-h-0'} gap-y-2 px-4 flex flex-col overflow-hidden`}>
                            {Array.isArray(assistant.suggestions) && assistant.suggestions.length > 0 && assistant.suggestions.map((suggestion) => (
                                <Suggestion key={suggestion} suggestion={suggestion} />
                            ))}
                        </div>
                        <ChatInput />
                    </div>
                </div>
                {/* Red panel on the right */}
                {/* <div className="flex"> */}
                <Panel />
                {/* </div> */}
            </main>
        </>
    )

}

export default ChatPage