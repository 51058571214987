import { PropsWithChildren, useEffect, useCallback } from "react"
import { Client } from "@langchain/langgraph-sdk";
import { useSession } from "../components/ducks/session/export";
import { useAssistant } from "../components/ducks/assistant/export";
import { useProject } from "../components/ducks/project/export";
import { useSteps } from "../components/ducks/steps/export";
import LoadingPage from "../../pages/Loading/LoadingPage";
import { useUser } from "../components/ducks/user/export";

export const client = new Client({
    apiUrl: process.env.REACT_APP_LANGGRAPH_API_URL,
    defaultHeaders: {
        'x-api-key': process.env.REACT_APP_LANGSMITH_API_KEY
    }
});

const PathFinderInit = (props: PropsWithChildren) => {
    const session = useSession();
    const assistant = useAssistant();
    const project = useProject();
    const user = useUser();

    useEffect(() => {
        session.actions.setLoading(true)
        if (session.data.thread) {
            assistant.thunks.getMessagesThunk();
        } else {
            session.thunks.newSession(undefined);
            session.thunks.createThread(undefined)
        }
        project.thunks.fetchProjectThunk();
        user.thunks.getProjectsByEmailThunk();
        session.actions.setLoading(false)
    }, [session.data.thread?.thread_id]);

    if (project.initialized) {
        return <>{props.children}</>;
    } else {
        return <LoadingPage label={"Fetching Project.."} />
    }
}

export default PathFinderInit