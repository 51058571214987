import { toastSlice } from "../toast/slice";
import { sessionSlice } from "../session/slice";
import { Thunk, serializeDate } from "../utils";
import { API } from "./api";
import { projectSlice } from "./slice";
import axios from "axios";
import { assistantSlice } from "../assistant/slice";
import { Project } from "./state";
import { Thread } from "@langchain/langgraph-sdk";
import { panelSlice } from "../panel/slice";
import { widgetSlice } from "../widget/slice";
import { stepsSlice } from "../steps/slice";
import { addDays } from "date-fns";

export namespace ProjectThunks {
    export const fetchProjectThunk: Thunk<void> = () => async (dispatch, getState) => {
        const thread = getState().session.data.thread!
        logger.log("[PROJECT THUNK] 🎬 Getting project from thread ID: ", thread?.thread_id);

        try {
            if (thread?.thread_id && getState().auth.data.email) {
                dispatch(projectSlice.actions.setLoading(true));

                dispatch(assistantSlice.actions.reset())

                const response = await API.getProjectByThread({ thread: thread });

                if (response.status === 200) {
                    logger.log("[PROJECT THUNK] ✅ Project fetched successfully");
                    await dispatch(projectSlice.actions.setProject({
                        title: response.data.projectTitle,
                        description: response.data.projectDescription,
                        image: {
                            url: response.data.imageUrl
                        },
                        lastMessaged: response.data.lastMessaged
                    }));
                    if (getState().session.data.thread !== thread) {
                        dispatch(sessionSlice.actions.setThread(
                            thread,
                        ))
                    }
                    if (response.data.steps !== "") dispatch(stepsSlice.actions.setSteps(JSON.parse(response.data.steps)))
                } else {
                    throw new Error(response.data);
                }
            }
        } catch (error: any) {
            logger.log("[PROJECT THUNK] 🔴 Error fetching project", error);
            // dispatch(toastSlice.actions.setToast({
            //     type: "error",
            //     message: "Failed to fetch project",
            //     error: error.message || "Unknown error occurred",
            //     fatal: false
            // }));
        } finally {
            dispatch(projectSlice.actions.setLoading(false));
            logger.log("[PROJECT THUNK] 🔚 Get project from thread ID completed");
            dispatch(projectSlice.actions.setInit(true))
        }
    };

    export const updateLastLoggedInThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[PROJECT THUNK] 🎬 Updating last logged in");
        dispatch(projectSlice.actions.setLoading(true))

        try {

            const thread = getState().session.data.thread;
            const userEmail = getState().auth.data.email;

            if (!thread || !userEmail) {
                throw new Error("Thread or user email is missing");
            }

            const response = await API.updateLastLoggedIn({
                thread,
                userEmail
            });

            if (response.status === 200) {
                logger.log("[PROJECT THUNK] ✅ Last logged in updated successfully");
                dispatch(projectSlice.actions.setLastMessaged(serializeDate(new Date())))
            } else {
                throw new Error(response.data);
            }
        } catch (error: any) {
            logger.error("[PROJECT THUNK] 🔴 Error updating last logged in", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to update last logged in",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            logger.log("[PROJECT THUNK] 🔚 Update last logged in completed");
            dispatch(projectSlice.actions.setLoading(false))
        }
    };

    export const saveProjectThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[PROJECT THUNK] 🟣 Saving project");

        try {
            dispatch(projectSlice.actions.setLoading(true));

            const response = await API.saveProject({
                thread: getState().session.data.thread!,
                userEmail: getState().auth.data.email,
                title: getState().project.data.title,
                description: getState().project.data.description,
                image: getState().project.data.image
            });

            if (response.status !== 200) {
                throw new Error(response.data);
            }
        } catch (error: any) {
            logger.error("[PROJECT THUNK] 🔴 Error saving project", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to save project",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(projectSlice.actions.setLoading(false));
            logger.log("[PROJECT THUNK] 🟢 Save project completed");
        }
    };

    export const setProjectThunk: Thunk<any> = (args: any) => async (dispatch, getState) => {
        logger.log("[PROJECT THUNK] 🎬 Setting project", args);

        try {
            logger.log("[PROJECT INSPIRATION THUNK] 🔵 Add inspiration operation started");
            // dispatch(panelSlice.actions.setState('summary'));
            let parsedArgs: Project | undefined;
            if (typeof args === 'object' && args !== null) {
                parsedArgs = args as Project;
            } else {
                try {
                    parsedArgs = JSON.parse(args) as Project | undefined;
                } catch (error) {
                    logger.error("[PROJECT THUNK] 🔴 Error parsing args", error);
                    parsedArgs = undefined;
                }
            }
            if (parsedArgs && 'title' in parsedArgs) {
                const { title, description, image } = parsedArgs;
                dispatch(projectSlice.actions.setProject({
                    title,
                    description,
                    image
                }));
                dispatch(projectSlice.actions.setLoading(false));
                logger.log("[PROJECT THUNK] args", args);
            } else {
                dispatch(projectSlice.actions.setLoading(true));
            }
            // Call SaveProjectThunk after setting the project
            if (getState().auth.data.email) {
                dispatch(saveProjectThunk());
            }
            logger.log("[PROJECT THUNK] ✅ Project set successfully");
        } catch (error: any) {
            logger.error("[PROJECT THUNK] 🔴 Error setting project", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to set project",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
            dispatch(panelSlice.actions.reset());
        } finally {
            logger.log("[PROJECT THUNK] 🔚 Set project completed");
        }
    };
}
