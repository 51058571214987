import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../../init/reduxInit";
import { ProjectResourcesThunks } from "./thunks";
import { useMemo } from "react";
import { projectResourcesSlice } from "./slice";

// Create selectors
const selectProjectResourcePages = (state: RootState) => state.resources.resourcePages;
const selectCurrentPage = (state: RootState) => state.resources.resourcePages[state.resources.page];
const selectProjectLoading = (state: RootState) => state.resources.loading;
const selectPageNumber = (state: RootState) => state.resources.page;
// Add more selectors as needed

export const useResources = () => {
    const dispatch = useDispatch();

    // Use specific selectors instead of selecting the entire state
    const currentPage = useSelector(selectCurrentPage);
    const pageNumber = useSelector(selectPageNumber)
    const resourcePages = useSelector(selectProjectResourcePages);
    const loading = useSelector(selectProjectLoading);

    // Memoize actions and thunks
    const actions = useMemo(
        () => bindActionCreators(projectResourcesSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(ProjectResourcesThunks, dispatch),
        [dispatch]
    );

    return {
        loading,
        pageNumber,
        currentPage,
        resourcePages,
        actions,
        thunks
    };
};
