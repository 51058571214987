import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
import { userSlice } from "./slice";
import { UserThunks } from "./thunks";

export const useUser = () => {
    const state = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch();

    const actions = bindActionCreators(userSlice.actions, dispatch);
    const thunks = bindActionCreators(UserThunks, dispatch);

    return { state, actions, thunks };
}