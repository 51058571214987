import React, { InputHTMLAttributes, forwardRef } from 'react';
import './input.css';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => {
    return (
        <input
            ref={ref}
            className={`input ${className || ''}`}
            {...props}
        />
    );
});

export default Input;