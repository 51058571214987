import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Add this import
import { CircularProgress, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
// import CancelModal from "../../CancelModal";
import { GuideAPI } from "../../../guide-api";
import { useUser } from "../../../export";
import { GuideLearner } from "../../../../../../types/GuideLearner";
import { Learner } from "./types";
import CancelModal from "../../../../../grapes/molecules/cancelModal";
import { useAuth } from "../../../../auth/export";
import Card from "../../../../../grapes/atoms/card";
import { toastSlice } from "../../../../toast/slice";
import mixpanel from "mixpanel-browser";

type DisplayLearnersProps = {
    vertical?: boolean;
};

type LearnerInfo = {
    name: string;
    email: string;
    guidePrompt: string;
};

const DisplayLearners: React.FC<DisplayLearnersProps> = ({
    vertical,
}) => {
    const user = useUser();
    const auth = useAuth();
    const navigate = useNavigate(); // Add this line
    const [showModal, setShowModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [learnerEmailToUnlink, setlearnerEmailToUnlink] = useState("");
    const [learnerInfo, setLearnerInfo] = useState<{ [key: string]: LearnerInfo }>({});

    useEffect(() => {
        logger.log("[DISPLAY LEARNERS] Learners updated", user.state.data.learners);
        const fetchLearnerInfo = async () => {
            if (!user.state.data.learners || user.state.data.learners.length === 0) return;

            const learnerIds = user.state.data.learners
                .filter(learner => learner.learnerId)
                .map(learner => learner.learnerId!);

            try {
                const responses = await Promise.all(
                    learnerIds.map(id => GuideAPI.getLearnerInfoById({ id }))
                );

                const info: { [key: string]: LearnerInfo } = {};
                responses.forEach((response, index) => {
                    const data = response.data;
                    info[learnerIds[index]] = {
                        name: data.name,
                        email: data.email,
                        guidePrompt: data.teacherPrompt
                    };
                });

                setLearnerInfo(info);
            } catch (error) {
                logger.error("Error fetching learner info:", error);
            }
        };

        fetchLearnerInfo();
    }, [user.state.data.learners, auth.state.data.email]);

    const handleDelete = (learnerEmail: string) => {
        setlearnerEmailToUnlink(learnerEmail);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        logger.log("Teacher email", auth.state.data.email);
        logger.log("learner email", learnerEmailToUnlink);
        try {
            setDeleting(true);
            const response = await GuideAPI.deleteStudentFromTeacher({
                teacherEmail: auth.state.data.email,
                studentEmail: learnerEmailToUnlink,
            });
            if (response.status === 200) {
                GuideAPI.fetchLearners({ email: auth.state.data.email });
                
                toastSlice.actions.setToast({
                    type: 'success',
                    message: `Learner ${learnerEmailToUnlink} deleted successfully.`,
                    error: null,
                    fatal: false
                })
                logger.log(`learner ${learnerEmailToUnlink} deleted successfully.`);
                // Optionally, refresh the learners list or handle UI updates here
            } else {
                logger.error(`Failed to delete learner ${learnerEmailToUnlink}.`);
            }
        } catch (error) {
            logger.error(`Error deleting learner ${learnerEmailToUnlink}:`, error);
        }
        setDeleting(false);
        setlearnerEmailToUnlink("");
        setShowModal(false);
    };

    const handleCardClick = (email: string) => {
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Guide seeing Learner Overview', { learnerEmail: email, guideEmail: auth.state.data.email });
        }
        navigate(`/guide-dashboard/learner/${email}`);
    };

    if (user.state.data.learners === null) {
        return (
            <div
                className="flex w-full h-full py-20 items-center justify-center"
            >
                <CircularProgress />
            </div>
        );
    }

    if (user.state.data.learners.length === 0) {
        return (
            <div
                className="flex w-full h-full py-20 items-center justify-center"
            >
                <p className="text-xl text-red-500 px-8 font-bold">No learners added to your dashboard yet. Click "Add Learners" on the top right to get started.</p>
            </div>
        );
    }

    if (!learnerInfo[user.state.data.learners[0]?.learnerId!]?.name) {
        return (
            <div
                className={`flex ${vertical ? "flex-col w-full space-y-4" : "flex-wrap gap-4 p-4"
                    }`}
            >
                {[...Array(user.state.data.learners.length)].map((_, index) => (
                    <div key={index}>
                        <Skeleton
                            variant="rectangular"
                            width={256}
                            height={96}
                            className="rounded-md"
                        />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <>
            <div
                className={`flex ${vertical ? "flex-col w-full space-y-4" : "flex-wrap gap-4 p-4"
                    }`}
            >
                {user.state.data.learners.map((learner: GuideLearner, index: number) => (
                    <Card
                        key={learner.learnerId}
                        title={learnerInfo[learner.learnerId!]?.name || `Learner ${index + 1}`}
                        email={learnerInfo[learner.learnerId!]?.email || 'Loading...'}
                        onClick={() => handleCardClick(learnerInfo[learner.learnerId!]?.email)}
                        handleDelete={handleDelete}
                    />
                ))}
            </div>
            {showModal && (
                <CancelModal
                    showModal={showModal}
                    handleConfirmCancel={handleConfirmDelete}
                    setShowModal={setShowModal}
                    loading={deleting}
                    text="Are you sure you want to unlink this learner from your dashboard?"
                />
            )}
        </>
    );
};

export default DisplayLearners;