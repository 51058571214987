import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../../init/reduxInit";
import { projectUploadSlice } from "./slice";
import { useMemo } from "react";
import { ProjectUploadThunks } from "./thunks";

// Create selectors
const selectUploadStatus = (state: RootState) => state.upload.status;
const selectUpload = (state: RootState) => state.upload.upload;
const selectFiles = (state: RootState) => state.upload.files;
const selectStep = (state: RootState) => state.upload.stepToUpload;

export const useUpload = () => {
    const dispatch = useDispatch();

    // Use specific selectors instead of selecting the entire state
    const status = useSelector(selectUploadStatus);
    const upload = useSelector(selectUpload);
    const files = useSelector(selectFiles);
    const step = useSelector(selectStep)

    // Memoize actions and thunks
    const actions = useMemo(
        () => bindActionCreators(projectUploadSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(ProjectUploadThunks, dispatch),
        [dispatch]
    );

    return {
        status,
        upload,
        files,
        step,
        actions,
        thunks
    };
};
