import mixpanel from "mixpanel-browser";
import { panelSlice } from "../panel/slice";
import { toastSlice } from "../toast/slice";
import { Thunk } from "../utils";
import { widgetSlice } from "../widget/slice";
import { API } from "./api";
import { stepsSlice } from "./slice";
import { Step } from "./state";

export namespace StepsThunks {

    export const addSteps: Thunk<any> = (args: any) => async (dispatch, getState) => {
        try {
            logger.log("[PROJECT STEPS THUNK] 🔵 Add steps operation starting");
            // dispatch(panelSlice.actions.setState('steps'))
            // dispatch(widgetSlice.actions.setState('steps'))
            const parsedSteps = JSON.parse(args);
            const steps = Array.isArray(parsedSteps) ? parsedSteps : [parsedSteps];
            if (steps.length > 0 && Object.keys(steps[0]).length !== 0) {
                for (const step of steps) {
                    dispatch(stepsSlice.actions.addStep(step));
                    dispatch(stepsSlice.actions.setLoading(false))
                }
                dispatch(saveStepsThunk())
                if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                    mixpanel.track('Step Added', { steps: steps });
                }
            } else {
                dispatch(stepsSlice.actions.setLoading(true))
            }
        } catch (error: any) {
            logger.log("[PROJECT STEPS THUNK] 🔴 Error adding steps", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to add the images.",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            logger.log("[ASSISTANT THUNK] 🟢 Add steps complete");
        }
    }

    export const addStep: Thunk<any> = (title: string) => async (dispatch, getState) => {
        try {
            dispatch(stepsSlice.actions.setLoading(true))
            logger.log("[PROJECT STEPS THUNK] 🔵 Add step started");
            // dispatch(panelSlice.actions.setState('steps'))
            // dispatch(widgetSlice.actions.setState('steps'))
            dispatch(stepsSlice.actions.addStep({ title: title }));
            dispatch(saveStepsThunk())
            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                mixpanel.track('Step Added', { title: title });
            }

        } catch (error: any) {
            logger.log("[PROJECT STEPS THUNK] 🔴 Error adding step", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to add the images.",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            logger.log("[ASSISTANT THUNK] 🟢 Add step complete");
            dispatch(stepsSlice.actions.setLoading(false))
        }
    }

    interface UpdatedStep {
        title: string;
        description: string;
        todos: string[];
    }

    interface UpdatedSteps {
        TODO: UpdatedStep[];
        DOING: UpdatedStep[];
        DONE: UpdatedStep[];
    }

    export const setStatusThunk: Thunk<Step> = (step) => async (dispatch) => {
        try {
            dispatch(stepsSlice.actions.setStatus({ stepId: step.id, status: step.status }));
            dispatch(saveStepsThunk());
            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                mixpanel.track('Step Status Changed', { stepId: step.id, status: step.status });
            }
        } catch (error: any) {
            logger.error("[STATUS THUNK] 🔴 Error setting step status", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to set step status.",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        }
    }

    // Add this mapping function
    const mapStepsToNewSchema = (steps: Step[]): UpdatedSteps => {
        const result: UpdatedSteps = { TODO: [], DOING: [], DONE: [] };

        steps.forEach(step => {
            const updatedStep: UpdatedStep = {
                title: step.title,
                description: "",
                todos: []
            };

            switch (step.status) {
                case "TODO":
                    result.TODO.push(updatedStep);
                    break;
                case "DOING":
                    result.DOING.push(updatedStep);
                    break;
                case "DONE":
                    result.DONE.push(updatedStep);
                    break;
                default:
                // result.TODO.push(updatedStep);
            }
        });

        return result;
    }

    export const saveStepsThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[PROJECT THUNK] 🟣 Saving project");

        try {
            dispatch(stepsSlice.actions.setLoading(true));

            const response = await API.saveSteps({
                thread: getState().session.data.thread!,
                userEmail: getState().auth.data.email,
                title: getState().project.data.title,
                description: getState().project.data.description,
                image: getState().project.data.image,
                steps: getState().steps.steps
            });

            if (response.status !== 200) {
                throw new Error(response.data);
            }
        } catch (error: any) {
            logger.error("[PROJECT THUNK] 🔴 Error saving project", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to save project",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(stepsSlice.actions.setLoading(false));
            logger.log("[PROJECT THUNK] 🟢 Save project completed");
        }
    };

    export const fetchStepsThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[STEPS THUNK] 🔵 Fetching steps");

        try {
            dispatch(stepsSlice.actions.setLoading(true));

            const response = await API.fetchSteps({
                thread: getState().session.data.thread!,
                userEmail: getState().auth.data.email
            });

            if (response.status !== 200) {
                throw new Error(response.data);
            }

            const fetchedSteps = response.data.steps;

            dispatch(stepsSlice.actions.setSteps(fetchedSteps));

            logger.log("[STEPS THUNK] 🟢 Steps fetched successfully");
        } catch (error: any) {
            logger.error("[STEPS THUNK] 🔴 Error fetching steps", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to fetch steps",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(stepsSlice.actions.setLoading(false));
        }
    };

}