import React, { useState, useEffect, useRef } from "react"
import { functionMap } from "../../../functions"
import { useAssistant } from "../../export"
import { Message } from "../../state"
import { usePanel } from "../../../panel/export"
import { useWidget } from "../../../widget/export"
import { Player } from "@lottiefiles/react-lottie-player";
import animationLottie from '../../../../../../assets/lottie/loading-anim.json'
import * as Icon from "solar-icon-set"
import Button from "../../../../grapes/atoms/button/Button"
import { useCartesia } from "../../../cartesia/export" // Add this import
import { isCartesiaInitialized } from '../../../../../init/cartesiaInit';

const ChatHistory = () => {
    const assistant = useAssistant()
    const panel = usePanel()
    const widget = useWidget()
    const cartesia = useCartesia() // Add this line
    const chatEndRef = useRef<HTMLDivElement | null>(null) // Create a ref for the chat container

    useEffect(() => {
        // Scroll to the bottom of the chat container
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [assistant.data.messages]) // Trigger on messages change

    const handlePlay = async (message: string) => {
        cartesia.thunks.generateSpeechThunk(message)
    };

    return (
        <div className="overflow-y-auto overflow-x-clip p-4">
            {assistant.data.messages.map((message: Message, index: number) => {
                if (index === assistant.data.messages.length - 1 && (message.content.message.length < 1 && !message.tool!)) {
                    return (
                        <div key={index} className={`mb-4 p-3 rounded-sm min-h-12 w-fit min-w-[60%] justify-start flex`}>
                            <Player
                                src={animationLottie}
                                background="transparent"
                                style={{ width: '24px', height: '24px' }}
                                loop
                                autoplay
                            />
                        </div>
                    )
                } else {
                    return (
                        <div>
                        {!(message.content.message.length === 0 && message.tool?.name === "getSuggestedActions") ? ((                        
                            <div key={index} className={`mb-4 p-3 rounded-sm min-h-12 ${message.role === 'human' ? "bg-blue-200 ml-auto" : "bg-gray-200 mr-auto"} w-fit max-w-[calc(100%-24px)] relative group`}>
                                {message.content.fileUrl && message.content.fileUrl.length > 0 && <img className="w-auto h-[125px] mb-3 rounded-sm object-contain ml-auto" src={message.content.fileUrl} alt="Uploaded Image" />}
                            <p>{message.content.message}</p>
                            {message.tool && functionMap[message.tool.name].state && message.tool.name !== "getSuggestedActions" &&  (
                                <button
                                    className={`inline-flex items-center gap-x-2 border border-black rounded py-2 px-4 bg-white hover:bg-gray-100 ${message.content.message ? 'mt-2' : ''}`}
                                    onClick={() => {
                                        //@ts-ignore
                                        panel.actions.setState(functionMap[message.tool!.name!].state)
                                        //@ts-ignore
                                        widget.actions.setState(functionMap[message!.tool!.name!].state)

                                        if (functionMap[message!.tool!.name!].versioning) {
                                            assistant.thunks.runFunction(functionMap[message!.tool!.name!].versioning!.set(message.tool!.version!))
                                        }
                                    }}
                                >
                                    {React.createElement(functionMap[message.tool.name].icon!, { size: 24 })}
                                    <p>
                                        {`${functionMap[message.tool.name].friendly_name}`}
                                    </p>
                                </button>
                            )}
                            {index < assistant.data.messages.length - 2 ? (
                                <div className={`flex ${message.role === 'human' ? 'left-[-40px]' : 'right-[-40px]'} mt-2 absolute top-0 opacity-0 group-hover:opacity-100 transition-opacity z-50`}>
                                    <Button
                                        variant="tertiary"
                                        onClick={() => handlePlay(message.content.message)}
                                        className="py-1"
                                    >
                                        <div className="flex flex-row justify-center items-center gap-x-1">
                                            <Icon.PlayCircle size={14} />
                                        </div>
                                    </Button>
                                </div>
                            ) : (
                                <div className={`flex ${message.role === 'human' ? 'justify-start' : 'justify-end'} mt-2`}>
                                    <Button
                                        variant="tertiary"
                                        onClick={() => handlePlay(message.content.message)}
                                        className="py-1"
                                    >
                                        <div className="flex flex-row justify-center items-center gap-x-1">
                                            <Icon.PlayCircle size={14} />
                                            <p className="text-sm">Listen</p>
                                        </div>
                                    </Button>
                                </div>
                                )}
                            </div>
                        )) : (<></>)}
                        </div>
                    )
                }
            })}
            <div ref={chatEndRef} />
        </div>
    )
}

export default ChatHistory