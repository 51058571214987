import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../../init/reduxInit";
import { ProjectInspirationsThunks } from "./thunks";
import { useMemo } from "react";
import { projectInspirationsSlice } from "./slice";

// Create selectors
const selectProjectInspirationPages = (state: RootState) => state.inspirations.inspirations;
const selectProjectInspirationHighlight = (state: RootState) => state.inspirations.highlighted;
const selectProjectInspirationLoading = (state: RootState) => state.inspirations.loading;
// Add more selectors as needed

export const useInspirations = () => {
    const dispatch = useDispatch();

    // Use specific selectors instead of selecting the entire state
    const inspirations = useSelector(selectProjectInspirationPages);
    const loading = useSelector(selectProjectInspirationLoading);
    const highlight = useSelector(selectProjectInspirationHighlight);

    // Memoize actions and thunks
    const actions = useMemo(
        () => bindActionCreators(projectInspirationsSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(ProjectInspirationsThunks, dispatch),
        [dispatch]
    );

    return {
        loading,
        inspirations,
        highlight,
        actions,
        thunks
    };
};
