import React, { PropsWithChildren } from 'react';
import Button from '../button/Button';
import * as Icons from 'solar-icon-set'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type CardProps = {
    title: string;
    email: string;
    className?: string;
    onClick?: () => void; // Add onClick prop
    handleDelete?: (email: string) => void;
};

const Card: React.FC<PropsWithChildren<CardProps>> = ({ title, email, className, onClick, handleDelete }) => {
    return (
        <div
            className={`bg-blue-600 bg-opacity-[5%] dark:bg-pf-dark-grey border border-blue-800 dark:border-pf-grey rounded-md p-4 ${className} relative ${onClick ? 'hover:bg-blue-200 cursor-pointer' : ''} w-64 h-18 flex flex-col justify-between`}
            onClick={onClick}
        >
            {handleDelete && (
                <Button
                    variant="secondary"
                    className="absolute top-1 right-1 text-gray-500 hover:text-gray-700"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        handleDelete(email);
                    }}
                >
                    <FontAwesomeIcon icon={faTimes} className="text-gray-600" />
                </Button>
            )}
            <div className=''>
                <h5 className={`font-semibold mb-2 text-black dark:text-white ${onClick ? '' : ''} truncate`}>
                    {title}
                </h5>
                <p className="text-gray-800 dark:text-pf-green truncate">
                    Email: {email}
                </p>
            </div>
        </div>
    );
};

export default Card;