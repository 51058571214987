import React, { useEffect, useMemo, useState } from 'react';
import { usePanel } from '../export';
import * as Icons from 'solar-icon-set'
import { styles } from '../../../../styling/styles';
import ImagePanel from '../../project/images/components/ImagePanel';
import ResourcePanel from '../../project/resources/components/Panel';
import InspirationPanel from '../../project/inspirations/components/Panel';
import SummaryPanel from '../../project/summary/components/SummaryPanel';
import StepsPanel from '../../steps/components/Panel';

const Panel = () => {
    const { state } = usePanel();
    const [isVisible, setIsVisible] = useState(false);
    const [showChildren, setShowChildren] = useState(false);

    useEffect(() => {
        if (state !== 'none') {
            setIsVisible(true);
            // Add delay before showing children
            const timer = setTimeout(() => {
                setShowChildren(true);
            }, 300);
            return () => clearTimeout(timer);
        } else {
            setIsVisible(false);
            setShowChildren(false);
        }
    }, [state]);

    return (
        <div
            className={`
                h-full z-40
                transition-all duration-300 ease-in-out
                ${isVisible ? 'w-1/2 translate-x-0' : 'w-0 translate-x-full'}
            `}
        >
            {/* Set the width to 150% when you want to add full screen */}
            <div className={`w-[100%] h-full p-2 pt-0 absolute right-0
                ${styles.panel} overflow-y-auto
                ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`
            }>
                {showChildren && (
                    <>
                        {state === 'images' && <ImagePanel />}
                        {state === 'resources' && <ResourcePanel />}
                        {state === 'inspirations' && <InspirationPanel />}
                        {state === 'summary' && <SummaryPanel />}
                        {state === 'steps' && <StepsPanel />}
                    </>
                )}
            </div>
        </div>
    );
};

export default Panel;