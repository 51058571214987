import { ThreadsClient } from "@langchain/langgraph-sdk/dist/client.mjs";
import { Run } from "..";
import { Thread } from "@langchain/langgraph-sdk";

export type SessionState = {
    data: {
        thread: Thread | null,
        messagesCount: number
    };
    view: "Board" | "Chat"
    loading: boolean
    isSocratic: boolean
};

export const initState: SessionState = {
    data: {
        thread: null,
        messagesCount: 0
    },
    view: "Chat",
    loading: false,
    isSocratic: false
};
