import { useEffect, useRef, useState, useCallback } from "react"
import { useAssistant } from "../../export"
import Input from "../../../../grapes/atoms/input/Input"
import Button from "../../../../grapes/atoms/button/Button"
import * as Icon from 'solar-icon-set'
import { useWidget } from "../../../widget/export"
import { useSteps } from "../../../steps/export"
import { useProject } from "../../../project/export"
import { useResources } from "../../../project/resources/export"
import { usePanel } from "../../../panel/export"
import { useUpload } from "../../../project/progress-upload/export"
import RecordRTC from "recordrtc"
import axios from "axios"
import mixpanel from "mixpanel-browser"
import { AssistantThunks } from "../../thunks"
import { useSession } from "../../../session/export"
import SocraticToggle from "../../../socratic/components/socraticToggle"


const ChatInput = () => {
    const assistant = useAssistant() // used for accessing export
    const session = useSession()
    const [message, setMessage] = useState<string>(assistant.data.messages.length === 0 ? 'I am interested in ' : '')
    const [placeholder, setPlaceholder] = useState<string>("Type here...")
    const [isRecording, setIsRecording] = useState<boolean>(false)
    const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false)
    const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
    const recorder = useRef<RecordRTC | null>(null)
    const [isProcessingMic, setIsProcessingMic] = useState<boolean>(false)
    const [recordingDuration, setRecordingDuration] = useState<number>(0)
    const inputRef = useRef<HTMLInputElement>(null)
    const widget = useWidget()
    const panel = usePanel()
    const steps = useSteps()
    const project = useProject()
    const resources = useResources()
    const upload = useUpload()
    const recordingInterval = useRef<NodeJS.Timeout | null>(null);
    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        if (assistant.data.messages.length === 0) {
            setMessage('I am interested in ');
        } else {
            setMessage('');
        }
    }, [session.data.thread?.thread_id]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (assistant.data.file) {
            assistant.actions.setLoading("true")
            await assistant.thunks.uploadChatFileThunk({ file: assistant.data.file });
            assistant.actions.setFile(null)
            assistant.actions.setLoading("false")
        }
        assistant.thunks.sendMessage(message)
        widget.actions.reset()
        setMessage('')
    }

    const handleMicrophoneClick = async () => {
        if (!isRecording) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                });
                setMediaStream(stream); // Store the stream in the state
                //@ts-ignore
                recorder.current = RecordRTC(stream, {
                    type: "audio",
                    mimeType: "audio/webm",
                });
                //@ts-ignore
                recorder.current.startRecording();
                setIsRecording(true);
                setMessage("");
                setPlaceholder("I'm listening...");
                recordingInterval.current = setInterval(() => {
                    setRecordingDuration((prevDuration) => prevDuration + 1);
                }, 1000);
            } catch (error) {
                logger.error("Error obtaining media stream:", error);
            }
        } else {
            if (recorder.current) {
                //@ts-ignore
                recorder.current.stopRecording(async function () {
                    setIsProcessingMic(true);
                    setPlaceholder("Give me a sec...");
                    //@ts-ignore
                    let blob = recorder.current.getBlob();

                    try {
                        const formData = new FormData();
                        formData.append("file", blob, "audio.webm");
                        formData.append("model", "whisper-1");
                        formData.append("response_format", "text");

                        // Send directly to OpenAI API Whispr
                        const response = await axios.post(
                            "https://api.openai.com/v1/audio/transcriptions",
                            formData,
                            {
                                headers: {
                                    "Authorization": `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                                    "Content-Type": "multipart/form-data"
                                }
                            }
                        );
                        const trimmedText = response.data.trim();

                        // Track the successful transcription event with Mixpanel
                        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                            mixpanel.track('Microphone used', {
                                'Transcription Length': trimmedText.length,
                                'Recording Duration': recordingDuration
                            });
                        }
                        setMessage(trimmedText);
                        setIsProcessingMic(false);
                        setRecordingDuration(0);
                    } catch (error) {
                        console.error("Error processing audio:", error);
                        setIsProcessingMic(false);
                        setRecordingDuration(0);
                    }

                    // Use the stored stream reference to stop the tracks
                    if (mediaStream) {
                        mediaStream.getTracks().forEach((track) => {
                            track.stop();
                        });
                        setMediaStream(null); // Clear the stored stream
                    } else {
                        // logger.log("No mediaStream found.");
                    }

                    // Clear the interval when stopping the recording
                    if (recordingInterval.current) {
                        clearInterval(recordingInterval.current);
                        recordingInterval.current = null;
                    }
                });
                setRecordingDuration(0);
                setIsProcessingMic(false);
                setIsRecording(false);
            } else {
                // logger.log("Recorder not initialized or already stopped.");
            }
        }
    };

    const handleFileUpload = useCallback((file: File) => {
        widget.actions.setState('file');
        if (file) {
            setIsUploadingFile(true);
            console.log('File selected:', file.name);
            assistant.actions.setFile(file);
            setIsUploadingFile(false);
        }
    }, [widget.actions, assistant.actions])

    const handleFileButtonClick = useCallback(() => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/jpeg,image/png';
        fileInput.onchange = (e: Event) => {
            const target = e.target as HTMLInputElement;
            const file = target.files?.[0];
            if (file) {
                handleFileUpload(file);
            }
        };
        fileInput.click();
    }, [handleFileUpload]);

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsDragging(true)
        widget.actions.setState('file')
    }, [])

    const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsDragging(false)
        widget.actions.setState('none')
    }, [])

    const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setIsDragging(false)
        const file = e.dataTransfer.files[0]
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            handleFileUpload(file)
        }
    }, [handleFileUpload])

    // useEffect(() => {
    //     if (assistant.data.messages.length < 4) {
    //         assistant.actions.setSuggestions([])
    //     } else if (!project.state.data.title) {
    //         assistant.actions.setSuggestions(["Show me some cool ideas!", "What could this project look like?"])
    //     } else if (steps.steps.filter((step) => step.status === "TODO").length < 1 && steps.steps.filter((step) => step.status === "DOING").length < 1) {
    //         assistant.actions.setSuggestions(["Let's work out the next step for this project"])
    //     } else if (resources.resourcePages.length < 1) {
    //         assistant.actions.setSuggestions(["Show me some resources"])
    //     } else if (upload.files.length < steps.steps.filter((step) => step.status === "DONE").length) {
    //         logger.log(steps.steps.filter((step) => step.status === "DONE").length)
    //         logger.log(upload.files.length)
    //         assistant.actions.setSuggestions(["I want to show you my work!", "Find me some more resources?"])
    //     }
    // }, [assistant.data.messages])

    useEffect(() => {
        inputRef.current?.focus()
    }, [])

    return (
        <div className="relative" onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
            {isDragging && (
                <div className="absolute w-11/12 inset-0 bottom-0 h-[200px] -mt-[190px] rounded-md left-1/2 transform -translate-x-1/2">
                </div>
            )}
            <div className={`bg-gray-100 flex flex-row px-4 py-3 rounded-sm w-full z-10 relative`}>
                {/* <div className="hover:-translate-y-2 transition-transform flex items-center justify-center">
                    <Button
                        type="button"
                        onClick={handleFileButtonClick}
                        variant="secondary"
                        disabled={isUploadingFile}
                    >
                        <Icon.Gallery size={24} />
                    </Button>
                </div> */}
                <div className="hover:-translate-y-2 transition-transform flex items-center justify-center">
                    <Button
                        type="button"
                        onClick={handleMicrophoneClick}
                        variant="secondary"
                        className={isRecording ? "w-[36px] h-[36px] !rounded-full border-2 border-gray-500 flex items-center justify-center mr-2" : ""}
                        disabled={isProcessingMic}
                    >
                        {isRecording ? (
                            <div className="">
                                <p className="text-xs font-bold text-gray-500 p-0">{recordingDuration}</p>
                            </div>
                        ) : (
                            <Icon.Microphone2 size={24} />
                        )}
                    </Button>
                </div>
                <form onSubmit={handleSubmit} className="flex flex-row w-full">
                    <Input
                        ref={inputRef}
                        autoFocus
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder={placeholder}
                        disabled={isRecording || isProcessingMic}
                    />
                    {project.data.title && steps.steps.filter((s) => s.status === "DOING").length < 1 && resources.resourcePages.length < steps.steps.filter((s) => s.status === "DONE").length + 1 &&
                        <div className="hover:-translate-y-2 transition-transform mr-4">
                            <Button
                                type="button"
                                onClick={() => {
                                    widget.actions.setState('steps')
                                    panel.actions.setState('steps')
                                }} variant={"primary"}
                            >
                                {/* <Icon.Upload size={24} /> */}
                                <p className="text-nowrap">+ Todo</p>
                            </Button>
                        </div>
                    }
                    {project.data.title && steps.steps.filter((s) => s.status === "DOING").length === 1 && (
                        <div className="hover:-translate-y-2 transition-transform mr-4">
                            <Button
                                type="button"
                                onClick={() => {
                                    widget.actions.setState('upload')
                                }} variant={"primary"}
                            >
                                <Icon.Upload size={24} />
                            </Button>
                        </div>
                    )}
                    <div className="flex items-center ml-2 mr-4">
                        <div className="mt-2">
                            <SocraticToggle />
                        </div>
                        <span className="ml-2 text-sm text-gray-500 whitespace-nowrap">Tell</span>
                    </div>
                    <div className={assistant.loading === "true" ? "" : "hover:translate-x-2 transition-transform"}>
                        <Button type="submit" variant={"primary"} disabled={assistant.loading === "true"}>
                            {assistant.loading === "true" ? (
                                <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <Icon.ArrowRight size={24} />
                            )}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChatInput