import { GuideLearner } from "../../../types/GuideLearner";

export type StepState = {
    steps: Step[],
    loading: boolean;
};

export type Step = {
    id: string
    title: string,
    status: 'TODO' | 'DOING' | "DONE"
}

export const initState: StepState = {
    steps: [],
    loading: false,
};
