import React, { useState } from 'react';
import AuthButton from '../../../ducks/auth/components/authButton';
import Button from '../../atoms/button/Button';
import * as SolarIcons from 'solar-icon-set';
import pfLogo from '../../../../../assets/images/pf-icon.svg';
import WordToggle from '../../atoms/wordToggle';
import { useSession } from '../../../ducks/session/export';
import GuideDashboardButton from '../../../ducks/user/components/guide/DashboardButton';
import ProjectHistoryDropdown from '../../../ducks/user/components/ProjectHistoryDropdown';
import { useSteps } from '../../../ducks/steps/export';
import { useProject } from '../../../ducks/project/export';
import * as Icons from 'solar-icon-set'
import SuggestFeatureModal from '../suggestFeatureModal';

const Navbar: React.FC = () => {
    const session = useSession()
    const { steps } = useSteps()
    const [isSuggestFeatureModalOpen, setIsSuggestFeatureModalOpen] = useState(false)

    const currentTask = steps.find(step => step.status === 'DOING') || null

    return (
        <nav className="flex items-center px-4 py-2 bg-white shadow-sm min-h-20">
            <div className="flex flex-grow justify-start items-center space-x-4">
                <img src={pfLogo} alt="Logo" className="w-8 h-8" />
                <ProjectHistoryDropdown />
                <Button
                    variant={'secondary'}
                    onClick={(() => {
                        session.actions.reset()
                    })}
                >
                    <Icons.AddSquare size={24} />
                </Button>
            </div>
            <div className="flex justify-end items-center space-x-4">
                {currentTask && <div className="justify-end bg-yellow-100 px-4 py-2 rounded max-w-[30vw] overflow-hidden whitespace-nowrap text-ellipsis flex-shrink-0">
                    <p className='font-bold truncate'>{`Current Task: `}<span className='font-normal truncate'>{`${currentTask!.title}`}</span></p>
                </div>}
                {/* {state.data.title && ( */}
                <div className='flex items-center justify-end flex-row h-[55px] w-full space-x-4 min-w-[650px]'>
                    <WordToggle
                        leftSelected={session.view === "Board"}
                        leftLabel="Board"
                        rightLabel="Chat"
                        onLeft={() => { session.actions.setView('Board') }}
                        onRight={() => { session.actions.setView('Chat') }}
                    />
                    {/* )} */}
                    <Button variant={'tertiary'} onClick={() => {
                        setIsSuggestFeatureModalOpen(true)
                    }}>
                        <p>Give Feedback</p>
                    </Button>
                    <GuideDashboardButton />
                    <AuthButton />
                </div>
            </div>
            <SuggestFeatureModal isOpen={isSuggestFeatureModalOpen} onClose={() => setIsSuggestFeatureModalOpen(false)} />
        </nav>
    );
};

export default Navbar;