import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../index"
import { WidgetState, initState } from "./state";
import { ErrorState } from "../../../types/ErrorState";

export const widgetSlice = createSlice({
    initialState: initState,
    name: "widget",
    reducers: {

        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            }
        },

        setError(state, action: Action<ErrorState>) {
            return {
                ...state,
                error: action.payload,
            }
        },

        setCancelled(state, action: Action<boolean>) {
            return {
                ...state,
                cancelled: action.payload,
            }
        },

        setState(state, action: Action<WidgetState['state']>) {
            return {
                ...state,
                state: action.payload,
            }
        },

        reset(state) {
            return {
                ...state,
                state: initState.state,
                loading: false,
                cancelled: false,
                error: undefined
            }
        }

    }
});