import React, { useMemo } from 'react';
import PanelHeader from '../../../panel/components/PanelHeader';
import { styles } from '../../../../../styling/styles';
import { useProject } from '../../export';

const SummaryPanel: React.FC = () => {

    const { loading, data } = useProject()

    // if (!project.data.title && !project.state.loading) {
    //     return <></>
    // }

    return (
        <div className="p-4 flex flex-col h-full">
            <PanelHeader
                onBack={() => logger.log('back')}
                onForward={() => logger.log('forward')}
            />
            <div className='flex w-full h-full justify-center items-center'>
                <div className="relative w-4/5 h-full">
                    {/* FUTURE JON: use this when adding revisions, adding a new one with every revision */}
                    {/* <div className={`${styles.panelCard} flex flex-col px-8 py-12 bg-grey-100 -rotate-[1.5deg] w-full h-full justify-center absolute top-0 left-0`}>
                    </div>
                    <div className={`${styles.panelCard} flex flex-col px-8 py-12 bg-grey-100 w-full h-full justify-center absolute top-0 left-0`}>
                    </div> */}
                    <div className={`${styles.panelCard} flex flex-col px-8 py-12 bg-white rotate-[1.5deg] w-full h-full justify-center relative z-10`}>
                        {loading ? (
                            <div className='flex flex-col w-full h-full justify-center p-4'>
                                <div className="h-8 bg-gray-200 rounded w-3/4 mb-4 animate-pulse"></div>
                                <div className="h-16 bg-gray-200 rounded w-full mb-8 animate-pulse"></div>
                                <div className="aspect-video flex w-full mt-4 bg-gray-200 rounded-sm animate-pulse"></div>
                            </div>
                        ) : (
                            <>
                                <div className="p-4">
                                    <h1 className="mb-2">{data.title}</h1>
                                    <p className='line-clamp-2 overflow-hidden text-ellipsis'>{data.description}</p>
                                </div>
                                <div className="aspect-video flex w-full mt-4">
                                    <img
                                        src={data.image.url}
                                        alt={data.title}
                                        className="w-full h-full object-cover rounded-sm"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SummaryPanel;