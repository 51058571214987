import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./utils/init/reduxInit";
import { MixpanelInit } from "./utils/init/mixpanelInit";
import { CartesiaInit } from "./utils/init/cartesiaInit";
import { AwsAmplifyInit } from "./utils/init/awsAmplifyInit";
import { PersistGate } from "redux-persist/integration/react";
import PathFinderInit from "./utils/init/pathFinderInit";
import SaveProjectWrapper from "./utils/components/ducks/project/SaveWrapper";
import Toast from "./utils/components/ducks/toast/Toast";
import './utils/Logger';

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <MixpanelInit>
                <AwsAmplifyInit>
                    {/* <PathFinderInit> */}
                    <CartesiaInit>
                        <SaveProjectWrapper>
                            <Toast>
                                <App />
                            </Toast>
                        </SaveProjectWrapper>
                    </CartesiaInit>
                    {/* </PathFinderInit> */}
                </AwsAmplifyInit>
            </MixpanelInit>
        </PersistGate>
    </Provider>
);

// Example usage of global logger
logger.info('Application rendered');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();