import React from 'react';
import * as Icons from 'solar-icon-set';
import { useModal } from '../export';
import Button from '../../../grapes/atoms/button/Button';
import { useWidget } from '../../widget/export';
import { useResources } from '../../project/resources/export';

const ModalHeader = () => {

    const modal = useModal()

    return (
        <div className="flex w-full justify-end items-center">
            <div className='gap-x-2 flex'>
            </div>
            <Button variant={'secondary'}>
                <Icons.CloseCircle iconStyle='Bold' size={24} onClick={() => {
                    modal.actions.reset()
                }} />
            </Button>
        </div>
    );
};

export default ModalHeader;

