import { client } from "../../../init/pathFinderInit";
import { authSlice } from "../auth/slice";
import { assistantSlice } from "./slice";
import axios from "axios";
import { FIREBASE_BASE_URL } from "../utils";

export namespace API {

    export const createThread = async () => {
        logger.log("Attempting to create a new thread...");
        const response = await client.threads.create();
        logger.log("Thread created successfully:", response);
        return response;
    }

    export const stream = (threadId: string, message: string, role: string, metadata: { userEmail: string, uploadedImage: string, isSocratic: boolean }) => {
        logger.log("[ASSISTANT API] 🔵 Streaming message to assistant", message, metadata);
        
        const streamResponse = client.runs.stream(
            threadId,
            "agent",
            {
                input: {
                    "messages": [
                        {
                            "role": role,
                            "content": message,
                        }
                    ]
                },
                streamMode: "events",
                multitaskStrategy: "interrupt",
                metadata: metadata,
                // config: {
                //     configurable: {
                //         userEmail: email
                //     }
                // }
            }
        );
        return streamResponse
    }

    export const getThreadState = async (threadId: string) => {
        try {
            const response: any = await client.threads.getState(threadId) // Ensure threadId is valid
            // logger.log(response)
            return response;
        } catch (error) {
            logger.error("Error fetching thread state:", error);
            throw error; // Handle error appropriately
        }
    }

    export const updateLastLoggedIn = async (email: string, threadId: string) => {
        try {
            const response = await axios.post(`${FIREBASE_BASE_URL}/updateLastLoggedIn`, {
                email: email.toLowerCase(),
                threadId: threadId
            });
            return response.data;
        } catch (error) {
            logger.error("Error updating last logged in:", error);
            throw error;
        }
    }

    export const updateLastMessaged = async (threadId: string) => {
        try {
            const response = await axios.post(`${FIREBASE_BASE_URL}/updateLastMessaged`, {
                threadId: threadId
            });
            return response.data;
        } catch (error) {
            logger.error("Error updating last logged in:", error);
            throw error;
        }
    }

    export const uploadFile = async (file: File, threadId: string, userEmail: string, userMsg: string) => {
        try {
            const response = await axios.get(`${FIREBASE_BASE_URL}/getS3ChatImageUploadUrl`, {
                params: {
                    fileType: file.type,
                    userMsg: userMsg,
                    threadId: threadId,
                    userEmail: userEmail
                }
            });
            // Return response.data directly
            return response.data;
        } catch (error) {
            logger.error("Error getting upload URL:", error);
            throw error;
        }
    }

    export const getImageURLFromAWS = async (key: string) => {
        try {
            const response = await axios.get(`${FIREBASE_BASE_URL}/getS3ProjectFile`, {
                params: {
                    key: key
                }
            });
            return response.data;
        } catch (error) {
            logger.error("Error getting image URL from AWS:", error);
            throw error;
        }
    }

    export const incrementNumMessages = async (threadId: string) => {
        try {
            const response = await axios.post(`${FIREBASE_BASE_URL}/addProjectPlanToUser`, {
                threadId: threadId,
                numMessages: { increment: 2 }
            });
            return response.data;
        } catch (error) {
            logger.error("Error incrementing numMessages:", error);
            throw error;
        }
    }
}