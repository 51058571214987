import mixpanel from "mixpanel-browser";
import { Thunk } from "../utils";
import { userSlice } from "./slice";
import { API } from "./api";
import { API as ProjectAPI } from "../project/api"
import { Project } from "./state"
import { toastSlice } from "../toast/slice";
import { authSlice } from "../auth/slice";
import { GuideAPI } from "./guide-api";
import { signUp } from "aws-amplify/auth";
import { createAsyncThunk } from '@reduxjs/toolkit';

export namespace UserThunks {
    export const getProjectsByEmailThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[USER THUNK] 🎬 Getting projects by email");

        try {
            dispatch(userSlice.actions.setLoading(true));

            const email = getState().auth.data.email

            if (email === "") return;

            const response = await ProjectAPI.getProjectsByEmail({ email: email });

            if (response.status === 200) {
                logger.log("[USER THUNK] ✅ Projects fetched successfully");
                dispatch(userSlice.actions.setProjects(response.data.threadIdsAndTitles));
            } else {
                throw new Error(response.data);
            }
        } catch (error: any) {
            logger.log("[USER THUNK] 🔴 Error fetching projects", error);
            logger.error(error)
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to fetch projects",
                error: error.message || "Unknown error occurred",
                fatal: false
            }))
        } finally {
            dispatch(userSlice.actions.setLoading(false));
            logger.log("[USER THUNK] 🔚 Get projects by email completed");
        }
    };

    export const getUserInfoThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[USER THUNK] 🎬 Getting user info by email");

        try {
            dispatch(userSlice.actions.setLoading(true));

            const email = getState().auth.data.email;

            if (email === "") return;

            const response = await API.getUserInfo({ email });

            if (response.status === 200) {
                logger.log("[USER THUNK] ✅ User info fetched successfully");
                // After successful login or signup
                if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                    mixpanel.identify(response.data.awsId);
                    mixpanel.people.set({
                        $email: response.data.email,
                        $name: response.data.name,
                        $role: response.data.role,
                        $Guides: response.data.guides,
                        $Learners: response.data.learners,
                    });
                    mixpanel.track('User Active');
                    mixpanel.track('User Logged In', {
                        $first_login: response.data.lastLoggedIn.length === 0 || false
                    });
                }
                dispatch(userSlice.actions.setUserInfo({
                    name: response.data.name,
                    role: response.data.role,
                    Guides: response.data.teachers || [],
                    Learners: response.data.students || []
                }));
            } else {
                throw new Error(response.data);
            }
        } catch (error: any) {
            logger.log("[USER THUNK] 🔴 Error fetching user info", error);
            logger.error(error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to fetch user role",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(userSlice.actions.setLoading(false));
            logger.log("[USER THUNK] 🔚 Get user info completed");
        }
    }


    export const createLearnerAccountsForGuide: Thunk<{ guideEmail: string, Learners: { name: string; email: string; password: string }[] }> = (props) => async (dispatch, getState) => {
        logger.log("[USER THUNK] 🎬 Creating Learner accounts for Guide");

        try {
            dispatch(userSlice.actions.setLoading(true));

            const results = await Promise.all(props.Learners.map(async (Learner) => {
                try {
                    // Sign up on AWS
                    const response = await signUp({
                        username: Learner.email.toLowerCase(),
                        password: Learner.password,
                    });

                    if (response.userId) {
                        // Call addPblUserToDb
                        await API.createUser({
                            awsId: response.userId!,
                            name: Learner.name,
                            email: Learner.email.toLowerCase(),
                            role: 'Learner'
                        });

                        // Add Learner to Guide
                        await GuideAPI.addLearnerToGuide({
                            guideEmail: props.guideEmail.toLowerCase(),
                            learnerEmail: Learner.email.toLowerCase(),
                        });

                        API.sendNewUserSlackMessage({
                            learnerName: Learner.name,
                            learnerEmail: Learner.email.toLowerCase(),
                            teacherEmail: props.guideEmail.toLowerCase()
                        });

                        logger.log(`[USER THUNK] ✅ Learner account created for ${Learner.email}`);
                        return { email: Learner.email, error: null };
                    } else {
                        throw new Error('Failed to create AWS account for Learner');
                    }
                } catch (error: any) {
                    logger.log(`[USER THUNK] 🔴 Error creating account for Learner ${Learner.email}`, error);
                    return { email: Learner.email, error: error.message || 'Failed to create account' };
                }
            }));

            return results;

        } catch (error: any) {
            logger.log("[USER THUNK] 🔴 Error creating Learner accounts", error);
            logger.error(error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to create Learner accounts",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
            throw error;
        } finally {
            dispatch(getUserInfoThunk());
            dispatch(userSlice.actions.setLoading(false));
            logger.log("[USER THUNK] 🔚 Create Learner accounts completed");
        }
    };

    export const createUserThunk: Thunk<{ awsId: string, name: string, email: string, role: string }> = (props) => async (dispatch, getState) => {
        logger.log("[USER THUNK] 🎬 Creating user");

        try {
            dispatch(userSlice.actions.setLoading(true));

            API.createUser({
                awsId: props.awsId,
                name: props.name,
                email: props.email,
                role: 'Learner'
            });
        } catch (error: any) {
            logger.log("[USER THUNK] 🔴 Error creating user", error);
            logger.error(error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to create user",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(userSlice.actions.setLoading(false));
            logger.log("[USER THUNK] 🔚 Create user completed");
        }
    };

    export const addLearnersToGuideThunk: Thunk<{ guideEmail: string, learnerEmails: { email: string }[] }> = (props) => async (dispatch, getState) => {
        logger.log("[USER THUNK] 🎬 Adding learners to guide");

        try {
            dispatch(userSlice.actions.setLoading(true));

            for (const learner of props.learnerEmails) {
                try {
                    await GuideAPI.addLearnerToGuide({
                        guideEmail: props.guideEmail.toLowerCase(),
                        learnerEmail: learner.email.toLowerCase(),
                    });
                } catch (error: any) {
                    logger.log(`[USER THUNK] 🔴 Error adding learner ${learner.email} to guide`, error);
                    dispatch(toastSlice.actions.setToast({
                        type: "error",
                        message: `Failed to add learner ${learner.email} to guide`,
                        error: error.message || "Unknown error occurred",
                        fatal: false
                    }));
                }
            }

            dispatch(toastSlice.actions.setToast({
                type: "success",
                message: "Learners added to guide successfully",
                error: null,
                fatal: false
            }));
        } catch (error: any) {
            logger.log("[USER THUNK] 🔴 Error adding learners to guide", error);
            logger.error(error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to add learners to guide",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(getUserInfoThunk());
            dispatch(userSlice.actions.setLoading(false));
            logger.log("[USER THUNK] 🔚 Add learners to guide completed");
        }
    };

    export const fetchLearnersForGuideThunk: Thunk<{ email: string }> = (props) => async (dispatch, getState) => {
        logger.log("[USER THUNK] 🎬 Fetching learners for guide");

        try {
            dispatch(userSlice.actions.setLoading(true));

            const response = await GuideAPI.fetchLearners({ email: props.email });

            if (response.status === 200 && response.data) {
                dispatch(userSlice.actions.setLearners(response.data));
                logger.log("[USER THUNK] ✅ Learners fetched successfully");
            } else {
                throw new Error("Failed to fetch learners");
            }
        } catch (error: any) {
            logger.log("[USER THUNK] 🔴 Error fetching learners for guide", error);
            logger.error(error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to fetch learners",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            dispatch(userSlice.actions.setLoading(false));
            logger.log("[USER THUNK] 🔚 Fetch learners for guide completed");
        }
    };

    // Define a new thunk for creating a single learner
    export const createSingleLearnerAccount = createAsyncThunk<
        { email: string; success: boolean; error?: string },
        { guideEmail: string; learner: { name: string; email: string; password: string } }
    >(
        'user/createSingleLearnerAccount',
        async ({ guideEmail, learner }, { rejectWithValue }) => {
            try {
                // Check if name, email and password are present
                if (!learner.name) {
                    throw new Error('Name is required');
                }
                if (!learner.email) {
                    throw new Error('Email is required');
                }
                if (!learner.password) {
                    throw new Error('Password is required');
                }
                if (learner.password.length < 6) {
                    throw new Error('Password needs to be >5 characters long.')
                }
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(learner.email)) {
                    throw new Error('Email not entered correctly')
                }

                // Sign up on AWS
                const response = await signUp({
                    username: learner.email.toLowerCase(),
                    password: learner.password,
                });

                if (response.userId) {
                    // Call addPblUserToDb
                    await API.createUser({
                        awsId: response.userId!,
                        name: learner.name,
                        email: learner.email.toLowerCase(),
                        role: 'Learner'
                    });

                    // Add Learner to Guide
                    await GuideAPI.addLearnerToGuide({
                        guideEmail: guideEmail.toLowerCase(),
                        learnerEmail: learner.email.toLowerCase(),
                    });

                    API.sendNewUserSlackMessage({
                        learnerName: learner.name,
                        learnerEmail: learner.email.toLowerCase(),
                        teacherEmail: guideEmail.toLowerCase()
                    });

                    logger.log(`[USER THUNK] ✅ Learner account created for ${learner.email}`);
                    return { email: learner.email, success: true };
                } else {
                    throw new Error('Failed to create AWS account for Learner');
                }
            } catch (error: any) {
                logger.log(`[USER THUNK] 🔴 Error creating account for Learner ${learner.email}`, error);
                return rejectWithValue({ email: learner.email, success: false, error: error.message || 'Failed to create account' });
            }
        }
    );

    // ... existing thunks
}