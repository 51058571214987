import { Step } from "../steps/state";
import { Image } from "./images/state";

export type ProjectState = {
    data: Project;
    loading: boolean;
    initialized: boolean;
};

export type Project = {
    threadId?: string;
    title: string;
    description: string;
    image: Image
    inputSteps?: Step[]
    summary?: string
    guidePrompt?: string
    lastMessaged?: string;
}

export const initState: ProjectState = {
    data: {
        title: "",
        description: "",
        image: {
            url: "",
        }, // todo: add a placeholder
    },
    initialized: false,
    loading: false,
};
