import { ErrorState } from "../../../types/ErrorState";
import { GuideLearner } from "../../../types/GuideLearner";

export type WidgetState = {
    state: "none" | "resources" | "inspirations" | "upload" | "options" | "steps" | "file"
    loading: boolean;
};

export const initState: WidgetState = {
    state: "none",
    loading: false,
};
