// CancelModal.tsx

import React, { Dispatch, SetStateAction } from "react";
import Button from "../../atoms/button/Button";

interface CancelModalProps {
  showModal: boolean;
  handleConfirmCancel: () => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  text: string;
}

const CancelModal: React.FC<CancelModalProps> = ({
  showModal,
  handleConfirmCancel,
  setShowModal,
  loading,
  text,
}) => {
  return (
    <>
      {showModal && (
        <div className="fixed top-[-4rem] left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-xl mx-4">
            <p className="text-xl mb-4 max-w-[30rem] text-center">{text}</p>
            <div className="flex justify-center mt-[2rem] space-x-4 items-center">
              <Button
                variant="error"
                onClick={handleConfirmCancel}
                disabled={loading}
              >
                <p>Yes</p>
              </Button>
              <Button
                variant="secondary"
                onClick={() => setShowModal(false)}
              >
                <p>Go Back</p>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CancelModal;