import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../../init/reduxInit";
import { ProjectImagesThunks } from "./thunks";
import { useMemo } from "react";
import { projectImagesSlice } from "./slice";

// Create selectors
const selectProjectImages = (state: RootState) => state.images.images;
const selectProjectLoading = (state: RootState) => state.images.loading;
// Add more selectors as needed

export const useImages = () => {
    const dispatch = useDispatch();

    // Use specific selectors instead of selecting the entire state
    const images = useSelector(selectProjectImages);
    const loading = useSelector(selectProjectLoading);

    // Memoize actions and thunks
    const actions = useMemo(
        () => bindActionCreators(projectImagesSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(ProjectImagesThunks, dispatch),
        [dispatch]
    );

    return {
        loading,
        images,
        actions,
        thunks
    };
};
