import { GuideLearner } from "../../../types/GuideLearner"

export type UserState = {
    data: {
        id: number,
        name: string,
        // email: string,
        // username: string,
        role: 'Anon' | 'Teacher' | 'Guide' | 'Learner' | 'Parent',
        type: string,
        guides: GuideLearner[] | null,
        learners: GuideLearner[] | null,
        projects: Project[],
    },
    loading: boolean,
}

export type Project = {
    threadId: string,
    projectTitle: string
}

export const initState: UserState = {
    data: {
        id: -1,
        name: '',
        // email: '',
        // username: '',
        role: 'Anon',
        type: '',
        guides: null,
        learners: null,
        projects: [],
    },
    loading: false,
}

export type Learner = {
    name: string;
    email: string;
    pageId?: string | null;
    isApproved: boolean;
};
