import { Dispatch } from "redux";
import { RootState, store } from "../../init/reduxInit";
import * as SolarIconSet from "solar-icon-set";
import { IconProps } from "solar-icon-set/dist/types";
import { ProjectImagesThunks } from "./project/images/thunks";
import { AssistantThunks } from "./assistant/thunks";
import { Thunk, unknownFunction } from "./utils";
import { ProjectResourcesThunks } from "./project/resources/thunks";
import { ProjectInspirationsThunks } from "./project/inspirations/thunks";
import { ProjectThunks } from "./project/thunks";
import { OptionsThunks } from "./options/thunks";
import { projectResourcesSlice } from "./project/resources/slice";
import { StepsThunks } from "./steps/thunks";
import mixpanel from "mixpanel-browser";

type FunctionMapItem = {
    func: (dispatch: typeof store.dispatch, args: any) => any;
    dontReplenish?: boolean
    icon?: React.ComponentType<IconProps>;
    friendly_name?: string;
    state?: string // describes the state that panel and widget use
    versioning?: {
        get: (getState: () => RootState) => any;
        set: (pageNumber: number) => any;
    }
};


export const functionMap: Record<string, FunctionMapItem> = {
    searchLearnModeImages: {
        func: (dispatch, args) => dispatch(ProjectImagesThunks.addImages(args)),
        icon: SolarIconSet.Gallery,
        friendly_name: "Find Awesome Images",
        state: "images"
    },
    searchVideoAndArticleResources: {
        func: (dispatch, args) => dispatch(ProjectResourcesThunks.addPage(args)),
        icon: SolarIconSet.BookBookmark,
        friendly_name: "Generate Cool Resources",
        state: "resources",
        versioning: {
            get: (getState) => getState().resources.resourcePages.length,
            set: (pageNumber) => projectResourcesSlice.actions.setPage(pageNumber),
        }
    },
    generateDefineModeProjectInspirations: {
        func: (dispatch, args) => dispatch(ProjectInspirationsThunks.addInspirations(args)),
        icon: SolarIconSet.HandStars,
        friendly_name: "Generate Fun Projects",
        state: "inspirations",
    },
    generateOneFineGrainedProjectGoalDescriptionImage: {
        func: (dispatch, args) => dispatch(ProjectThunks.setProjectThunk(args)),
        icon: SolarIconSet.Star,
        friendly_name: "Create Your Project",
        state: "summary"
    },
    generatePotentialOptionsWhenStuck: {
        func: (dispatch, args) => dispatch(OptionsThunks.addOptions(args)),
        icon: SolarIconSet.BranchingPathsUp,
        friendly_name: "Show Potential Paths",
        state: "options"
    },
    generateCandidateNextDefineStep: {
        func: (dispatch, args) => dispatch(StepsThunks.addSteps(args)),
        icon: SolarIconSet.Checklist,
        friendly_name: "Generated Step for Project",
        state: "steps"
    },
    notifySafetyRisk: {
        dontReplenish: true,
        func: (dispatch, args) => { },
        icon: SolarIconSet.ShieldWarning,
        friendly_name: "Not Implemented",
    },
    transition_state: {
        dontReplenish: true,
        func: (dispatch, args) => { },
        icon: SolarIconSet.ShieldWarning,
        friendly_name: "Not Implemented",
    },
    getSuggestedActions: {
        func: (dispatch, args) => dispatch(AssistantThunks.updateSuggestedActions(args)),
        icon: SolarIconSet.ShieldWarning,
        friendly_name: "Suggest Actions",
        state: "none"
    },
    unknownFunction: {
        func: (dispatch, args) => dispatch(unknownFunction(args)),
        icon: SolarIconSet.ShieldWarning,
        friendly_name: "Not Implemented",
        // state: "none"
    }
};

export const handleFunctionCall = (
    dispatch: Dispatch,
    functionName: string,
    args: any,
    replenish: boolean,
): void => {
    const func = functionMap[functionName as keyof typeof functionMap];;
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.track('Function Called', { name: functionName });
    }
    if (func) {
        logger.log("Function called:", functionName, args);
        logger.log()
        if (!replenish && !func.dontReplenish) {
            return func.func(dispatch, args);
        }
    } else {
        throw new Error(`Unknown function: ${functionName}`);
    }
};