import React, { useState, useCallback } from 'react';
import { useOptions } from '../export';
import * as Icons from 'solar-icon-set'
import { Option } from '../state';
import Button from '../../../grapes/atoms/button/Button';
import { useAssistant } from '../../assistant/export';
import { useWidget } from '../../widget/export';

const OptionsWidget: React.FC = () => {
    const { thunks, options } = useOptions();
    const assistant = useAssistant()
    const widget = useWidget()

    return (
        <div className="p-4 space-y-4">
            <div className='flex w-full flex-col gap-y-2'>
                {options.map((option: Option) => (
                    <Button
                        key={option.title}
                        className='w-full'
                        variant='tertiary'
                        onClick={() => {
                            assistant.thunks.sendMessage(option.title)
                            widget.actions.reset()
                        }}
                    >
                        <p>{option.title}</p>
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default OptionsWidget;