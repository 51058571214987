import { useState } from "react";
import { useForm } from "react-hook-form";
import { Label } from "flowbite-react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/components/ducks/auth/export";
import Button from "../../utils/components/grapes/atoms/button/Button";

interface ForgotPasswordFormData {
    email: string;
}

interface ResetPasswordFormData {
    confirmationCode: string;
    newPassword: string;
}

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
});

const resetPasswordSchema = Yup.object().shape({
    confirmationCode: Yup.string().required("Confirmation code is required"),
    newPassword: Yup.string().required("New password is required"),
});

const ForgotPassword = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [stage, setStage] = useState(1);
    const [email, setEmail] = useState("");

    const { register: registerStage1, handleSubmit: handleSubmitStage1, formState: { errors: errorsStage1 }, reset: resetStage1 } = useForm<ForgotPasswordFormData>({
        mode: "onBlur",
        resolver: yupResolver(forgotPasswordSchema),
    });

    const { register: registerStage2, handleSubmit: handleSubmitStage2, formState: { errors: errorsStage2 }, reset: resetStage2 } = useForm<ResetPasswordFormData>({
        mode: "onBlur",
        resolver: yupResolver(resetPasswordSchema),
    });

    const onSubmitStage1 = async (data: ForgotPasswordFormData) => {
        setLoading(true);
        setError("");
        try {
            await auth.thunks.forgotPasswordThunk({
                email: data.email,
            });
            setEmail(data.email);
            setStage(2);
            resetStage1(); // Clear the form after successful submission
        } catch (error: any) {
            setError("An error occurred. Please try again.");
            logger.error("Forgot password error:", error);
        } finally {
            setLoading(false);
        }
    };

    const onSubmitStage2 = async (data: ResetPasswordFormData) => {
        setLoading(true);
        setError("");
        try {
            const success = await auth.thunks.confirmResetPasswordThunk({
                username: email,
                confirmationCode: data.confirmationCode,
                newPassword: data.newPassword,
            });
            resetStage2(); // Clear the form after successful submission
            navigate("/login");
        } catch (error: any) {
            setError("An error occurred. Please try again.");
            logger.error("Reset password error:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-pf-purple bg-opacity-50 bg-center bg-no-repeat">
            <div className="bg-white p-8 rounded-lg shadow-md">
                <h1 className="text-3xl font-bold mb-4 text-center">Forgot Password</h1>
                {stage === 1 ? (
                    <>
                        <p className="text-gray-600 mb-6 text-center w-full">Enter your email to reset your password</p>
                        <form onSubmit={handleSubmitStage1(onSubmitStage1)}>
                            {error && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                                    <span className="block sm:inline">{error}</span>
                                </div>
                            )}
                            <div className="flex flex-col space-y-2 my-10">
                                <Label htmlFor="email" value="Your email" />
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="name@heypathfinder.com"
                                    {...registerStage1("email")}
                                    className="w-full bg-gray-100 border border-gray-800 rounded-md p-2 w-[30vw] min-w-[200px]"
                                />
                                {errorsStage1.email && (
                                    <p className="text-red-500 text-xs italic mt-1">{errorsStage1.email.message}</p>
                                )}
                            </div>
                            <div className="flex items-center mt-4 justify-between mb-4">
                                <a
                                    className="inline-block align-baseline font-bold text-sm text-pf-navy hover:text-blue-800 underline"
                                    href="/login"
                                >
                                    Back to Login
                                </a>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? "Loading..." : "Send Reset Code"}
                                </Button>
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <p className="text-gray-600 mb-6 text-center">Enter the confirmation code and your new password</p>
                        <form onSubmit={handleSubmitStage2(onSubmitStage2)}>
                            {error && (
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                                    <span className="block sm:inline">{error}</span>
                                </div>
                            )}
                            <div className="flex flex-col space-y-2 mt-10 mb-4">
                                <Label htmlFor="confirmationCode" value="Confirmation Code" />
                                <input
                                    id="confirmationCode"
                                    type="text"
                                    {...registerStage2("confirmationCode")}
                                    className="w-full bg-gray-100 border border-gray-800 rounded-md p-2 w-[30vw] min-w-[200px]"
                                />
                                {errorsStage2.confirmationCode && (
                                    <p className="text-red-500 text-xs italic mt-1">{errorsStage2.confirmationCode.message}</p>
                                )}
                            </div>
                            <div className="flex flex-col space-y-2 mb-10">
                                <Label htmlFor="newPassword" value="New Password" />
                                <input
                                    id="newPassword"
                                    type="password"
                                    {...registerStage2("newPassword")}
                                    className="sensitive w-full bg-gray-100 border border-gray-800 rounded-md p-2 w-[30vw] min-w-[200px]"
                                />
                                {errorsStage2.newPassword && (
                                    <p className="text-red-500 text-xs italic mt-1">{errorsStage2.newPassword.message}</p>
                                )}
                            </div>
                            <div className="flex items-center justify-end mb-4">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? "Loading..." : "Reset Password"}
                                </Button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

export default ForgotPassword;