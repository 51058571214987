import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../../index";
import { Image, initState } from "./state";

export const projectImagesSlice = createSlice({
    initialState: initState,
    name: "projectImages",
    reducers: {
        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },

        addImages(state, action: Action<Image[]>) {
            return {
                ...state,
                images: [...action.payload, ...state.images],
            };
        },

        reset(state) {
            return {
                images: initState.images,
                loading: initState.loading
            };
        },
    },
});
