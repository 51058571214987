import React, { PropsWithChildren, ButtonHTMLAttributes } from 'react';
import './button.css';
import { Tooltip } from 'flowbite-react';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant: "primary" | "secondary" | "tertiary" | "error";
    loading?: boolean;
    label?: string;
};
const Button = ({ children, variant, label, loading,className, ...props }: PropsWithChildren<ButtonProps>) => {
    const isTextButton = React.Children.toArray(children).some(child =>
        React.isValidElement(child) &&
        typeof child.type === 'string' &&
        ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span'].includes(child.type)
    );

    const buttonClassName = `
        ${className || ''} 
        ${isTextButton ? 'px-4' : 'px-2'}
        py-2
        button 
        flex
        button--${variant} 
        items-center 
        justify-center 
        rounded-sm 
        h-auto
    `.trim().replace(/\s+/g, ' ');

    return (
        <button
            className={buttonClassName}
            {...props}
        >
            {loading && (
                <div className="flex items-center justify-center pr-3">
                    <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
            {children}
        </button>
    );
};

export default Button;