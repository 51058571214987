import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../index"
import { UserState, initState } from "./state";
import { GuideLearner } from "../../../types/GuideLearner";

export const userSlice = createSlice({
    initialState: initState,
    name: "user",
    reducers: {

        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            }
        },

        setAll(state, action: Action<UserState['data']>) {
            return {
                ...state,
                data: action.payload,
            }
        },

        setProjects(state, action: Action<UserState['data']['projects']>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    projects: action.payload,
                },
            }
        },

        setUserInfo(state, action: Action<{
            name: string,
            role: UserState['data']['role'],
            Guides: GuideLearner[],
            Learners: GuideLearner[]
        }>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    name: action.payload.name,
                    role: action.payload.role,
                    guides: action.payload.Guides,
                    learners: action.payload.Learners.map(learner => ({ id: learner.id, guideId: learner.teacherId, learnerId: learner.studentId, status: learner.status })),
                },
            }
        },

        setLearners(state, action: Action<GuideLearner[]>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    learners: action.payload,
                },
            }
        },

        reset(state) {
            return {
                ...state,
                data: initState.data,
                loading: false,
            }
        }

    }
});