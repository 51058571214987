import React, { useEffect, useState } from 'react';
import Button from '../../../grapes/atoms/button/Button';
import * as SolarIcons from 'solar-icon-set';
import { useProject } from '../../project/export';
import { useUser } from '../export';
import { Project } from '../state';
import { useSession } from '../../session/export';
import { CircularProgress, Skeleton } from '@mui/material';
import { useAuth } from '../../auth/export';
import { initState as authInitState } from '../../auth/state';
import { API as AssistantAPI } from '../../assistant/api';
import { useAssistant } from '../../assistant/export';
import mixpanel from 'mixpanel-browser';

const ProjectHistoryDropdown = () => {
    const project = useProject();
    const user = useUser();
    const auth = useAuth();
    const session = useSession();
    const assistant = useAssistant();
    const [isOpen, setIsOpen] = useState(false);

    const openDropdown = () => {
        if (user.state.data.projects.length === 0) {
            user.actions.setLoading(true);
            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                mixpanel.track('Project History Dropdown Opened', { email: auth.state.data.email });
            }
            user.actions.setLoading(false);
        }
        setIsOpen(!isOpen);
    };

    const openProject = (p: Project) => {
        // session.actions.setThread(
        //     { thread_id: p.threadId!, created_at: "", metadata: {}, updated_at: "" }
        // )
        session.thunks.newSession(p.threadId);
        // assistant.thunks.getMessagesThunk();
        // project.thunks.fetchProjectThunk();

        // project.thunks.fetchProjectThunk({ thread: { thread_id: p.threadId, created_at: "", updated_at: "", metadata: {} } });
    };

    const newThread = () => {
        session.thunks.newSession(undefined)
        setIsOpen(!isOpen)
        // session.actions.reset();
    };

    return (
        <div className="relative z-50">
            <Button
                variant="secondary"
                className="flex items-center space-x-2 px-2 w-full min-w-[250px] justify-between py-1 border rounded-full"
                onClick={openDropdown}
            >
                <div className="flex items-center">
                    {project.data.image.url.length > 0 && (
                        <img
                            src={project.data.image.url}
                            alt="Project"
                            className="w-8 h-8 mr-3 object-cover"
                        />
                    )}
                    <p>{project.data.title ? project.data.title : "See previous projects"}</p>
                </div>
                {isOpen ? <SolarIcons.AltArrowUp size={16} /> : <SolarIcons.AltArrowDown size={16} />}
            </Button>
            {isOpen && (
                <div className="absolute mt-2 w-full bg-white border rounded-sm shadow-lg">
                    <Button variant="secondary" className="w-full bg-gray-200" onClick={() => newThread()}>Create a new project</Button>
                    {auth.state.data.email === authInitState.data.email ? (
                        <div className="flex flex-col justify-center items-center mt-2">
                            <Button variant="secondary" className="text-center border-2 font-semibold border-blue-200 m-2 py-2 rounded-md" onClick={() => window.location.href = "/login"}>
                                <p>Log in to see your previous projects</p>
                            </Button>
                            <hr className="mt-2 border-gray-200 mx-1"></hr>
                        </div>
                    ) : user.state.data.projects.length === 0 ? (
                        <div className="flex flex-col justify-center items-center mt-2">
                            <Skeleton variant="text" width="90%" height={40} />
                            <hr className="border-gray-200 mx-1"></hr>
                            <Skeleton variant="text" width="40%" height={40} />
                            <hr className="border-gray-200 mx-1"></hr>
                            <Skeleton variant="text" width="60%" height={40} />
                            <hr className="border-gray-200 mx-1"></hr>
                            <Skeleton variant="text" width="70%" height={40} />
                        </div>
                    ) : (
                        user.state.data.projects.map((project, index) => (
                            project.projectTitle.length > 0 && (
                                <div key={index}>
                                    <Button variant="secondary" className="w-full p-2 items-start justify-start" onClick={() => openProject(project)}>{project.projectTitle}</Button>
                                    <hr className="border-gray-200 mx-1"></hr>
                                </div>
                            )
                        ))
                    )}
                </div>
            )}
        </div>
    );
}

export default ProjectHistoryDropdown;