import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../index"
import { Step, initState } from "./state";

export const stepsSlice = createSlice({
    initialState: initState,
    name: "steps",
    reducers: {

        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            }
        },

        setStatus(state, action: Action<{ stepId: string; status: Step['status'] }>) {
            // There can only ever be 1 card in doing
            if (action.payload.status === 'DOING') {
                return {
                    ...state,
                    steps: state.steps.map(step =>
                        step.id === action.payload.stepId ? { ...step, status: action.payload.status } :
                            step.status === 'DOING' ? { ...step, status: 'TODO' } : step
                    )
                };
            }
            const { stepId, status } = action.payload;
            return {
                ...state,
                steps: state.steps.map(step =>
                    step.id === stepId ? { ...step, status } : step
                )
            };
        },

        editStep(state, action: Action<{ id: string; title: string }>) {
            return {
                ...state,
                steps: state.steps.map(step =>
                    step.id === action.payload.id ? { ...step, title: action.payload.title } : step
                )
            };
        },

        setDone(state) {
            return {
                ...state,
                steps: state.steps.map(step =>
                    step.status === 'DOING' ? { ...step, status: 'DONE' } : step
                )
            };
        },

        addStep(state, action: Action<Partial<Step>>) {
            const newStep: Step = {
                id: action.payload.id && action.payload.id.trim() !== '' ? action.payload.id : `step_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
                status: state.steps.some(step => step.status === 'DOING') ? (action.payload.status || 'TODO') : 'DOING',
                title: action.payload.title || '',
            };
            console.log("AMAAN steps", newStep)
            return {
                ...state,
                steps: [...state.steps, newStep]
            };
        },

        setSteps(state, action: Action<Step[]>) {
            return {
                ...state,
                steps: action.payload.map(step => ({
                    ...step,
                    id: step.id || `step_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
                    status: step.status || 'TODO'
                }))
            };
        },

        deleteStep(state, action: Action<{ id: string }>) {
            return {
                ...state,
                steps: state.steps.filter(step => step.id !== action.payload.id)
            };
        },


        reset(state) {
            return {
                ...initState
            }
        }

    }
});