import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../index";
import { Project, ProjectState, initState } from "./state";
import { serializeDate } from "../utils";

export const projectSlice = createSlice({
    initialState: initState,
    name: "project",
    reducers: {
        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },

        setProject(state, action: Action<Partial<Project>>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        },

        setLastMessaged(state, action: Action<string>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    lastMessaged: action.payload
                }
            }
        },

        setInit(state, action: Action<boolean>) {
            return {
                ...state,
                initialized: action.payload,
            }
        },

        reset(state) {
            return {
                ...initState
            };
        },
    },
});
