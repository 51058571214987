import { Thread } from "@langchain/langgraph-sdk";
import axios from "axios";
import { FIREBASE_BASE_URL } from "../utils";
import { Project } from "../project/state";
import { Step } from "./state";

export namespace API {
    export const saveSteps = async (props: Partial<Project> & { steps: any, userEmail: string, thread: Thread }) => {
        const response = await axios.post(
            `${FIREBASE_BASE_URL}/addProjectPlanToUser`,
            {
                userEmail: props.userEmail.toLowerCase(),
                threadId: props.thread.thread_id,
                projectTitle: props.title,
                projectDescription: props.description,
                imageUrl: props.image!.url,
                steps: props.steps
            }
        );
        return response
    }

    export const fetchSteps = async (props: { userEmail: string, thread: Thread }) => {
        const response = await axios.get(
            `${FIREBASE_BASE_URL}/getProjectPlanFromUser`,
            {
                params: {
                    userEmail: props.userEmail.toLowerCase(),
                    threadId: props.thread.thread_id
                }
            }
        );
        return response;
    }
}