import React, { useEffect } from 'react';
import { useSession } from '../../utils/components/ducks/session/export';
import BoardPage from '../Board/BoardPage';
import ChatPage from '../Chat/ChatPage';
import Navbar from '../../utils/components/grapes/molecules/navbar';
import { CircularProgress } from '@mui/material';
import { Modal } from '../../utils/components/ducks/modal/components/Modal';

type LoadingPageProps = {
    label?: string
}

const LoadingPage: React.FC<LoadingPageProps> = (props: LoadingPageProps) => {

    const session = useSession()

    useEffect(() => {
        console.log("SESSION", session.data.thread?.thread_id)
    }, [])

    return (
        <div className="flex flex-col h-screen w-screen bg-white">
            <div className="flex justify-center items-center h-full w-full flex-col gap-8">
                <CircularProgress />
                <p>{props.label}</p>
            </div>
        </div>
    );
};

export default LoadingPage;
