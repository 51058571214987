import React, { useEffect, useState } from 'react';
import { useSteps } from '../../export';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import * as Icons from 'solar-icon-set'
import { Step } from '../../state';
import Button from '../../../../grapes/atoms/button/Button';
import Input from '../../../../grapes/atoms/input/Input';
import { useUpload } from '../../../project/progress-upload/export';
import { ProjectFile } from '../../../project/progress-upload/state';

interface KanbanCardProps {
    step: Step;
    index: number;
    isDraggable?: boolean;
    isNew?: boolean;
    onSave?: (title: string) => void;
    onCancel?: () => void;
    inputSteps?: Step[];
}

const KanbanCard: React.FC<KanbanCardProps> = ({ step, index, isDraggable = false, isNew = false, onSave, onCancel, inputSteps }) => {
    const stepsDuck = useSteps();
    const upload = useUpload()
    const [isEditing, setIsEditing] = useState(isNew);
    const [editedTitle, setEditedTitle] = useState(step.title);
    const isInputSteps = inputSteps === undefined ? false : !!inputSteps;

    const handleSave = () => {
        if (isNew) {
            onSave && onSave(editedTitle);
        } else {
            stepsDuck.actions.editStep({ id: step.id, title: editedTitle })
            setIsEditing(false);
        }
    };

    const handleCancel = () => {
        if (isNew) {
            onCancel && onCancel();
        } else {
            setEditedTitle(step.title);
            setIsEditing(false);
        }
    };

    const handleClick = (e: React.MouseEvent) => {
        if (!isEditing && !isInputSteps) {
            e.preventDefault();
            e.stopPropagation();
            if (step.status !== "DONE") {
                setIsEditing(true);
            }
        }
    };

    const handleTrashClick = (e: React.MouseEvent) => {
        stepsDuck.actions.deleteStep({ id: step.id })
    };

    const renderFilePreview = (file: ProjectFile) => {
        const isImage = file.bucket?.type.startsWith('image/')

        // return isImage ? (
        //     <img
        //         src={file.bucket?.uploadUrl}
        //         alt={file.bucket?.name}
        //         width={32}
        //         height={32}
        //         className="object-cover rounded"
        //     />
        // ) : (
        //     <Icons.File size={24} className="text-gray-500" />
        // );
        return (
            <div className='flex flex-row gap-x-2'>
                <Icons.File size={24} className="text-gray-500" />
                <p>{file.bucket?.name}</p>
            </div>
        )
    };

    const renderCard = (provided?: DraggableProvided) => (
        <div
            ref={provided?.innerRef}
            {...provided?.draggableProps}
            {...provided?.dragHandleProps}
            className="flex flex-row items-center justify-between bg-white p-4 rounded shadow mb-4"
            onClick={(e) => handleClick(e)}
        >
            {isEditing ? (
                <div className='flex flex-col gap-y-4 w-full h-full rounded-sm' onClick={(e) => e.stopPropagation()}>
                    <input
                        type="text"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        placeholder="Enter task title"
                        className="flex-grow px-2 py-2 border w-full bg-gray-100"
                        autoFocus
                    />
                    <div className="flex gap-2">
                        <Button variant='primary' onClick={handleSave}>Save</Button>
                        <Button variant='secondary' onClick={handleCancel}>Cancel</Button>
                    </div>
                </div>
            ) : (
                <div className='flex flex-row justify-between items-center w-full'>
                    <div className='flex flex-col gap-x-2'>
                        <h4 className="font-semibold text-lg w-full">{step.title}</h4>
                        <div className="flex gap-2">
                            {upload.files.filter((f) => f.step?.id === step.id).map((file) => (
                                <div key={file.bucket?.key} className="flex items-center">
                                    {renderFilePreview(file)}
                                </div>
                            ))}
                        </div>
                    </div>
                    {!isDraggable && (
                        <Button
                            variant='secondary'
                            onClick={(e) => {
                                e.stopPropagation();
                                if (step.status === "TODO") {
                                    stepsDuck.thunks.setStatusThunk({ id: step.id, title: step.title, status: "DOING" })
                                    upload.actions.setStep(step)
                                } else if (step.status === "DOING") {
                                    stepsDuck.thunks.setStatusThunk({ id: step.id, title: step.title, status: "TODO" })
                                    upload.actions.setStep(undefined)
                                }
                            }}
                            className="text-gray-600 hover:text-gray-800"
                        >
                            {!isInputSteps && (
                                step.status === "TODO" && !stepsDuck.steps.some((step) => step.status === "DOING") ? (
                                    <div className="flex flex-row items-center gap-x-2 text-nowrap">
                                        <p>Move to doing</p>
                                        <Icons.ArrowUp size={18} />
                                    </div>
                                ) : step.status === "DOING" && (
                                    <div className="flex flex-row items-center gap-x-2 text-nowrap">
                                        <p>Move to todo</p>
                                        <Icons.ArrowDown size={18} />
                                    </div>
                                )
                            )}
                        </Button>
                    )}
                    {!isInputSteps && (
                        <Button
                            variant='secondary'
                            onClick={handleTrashClick}
                        >
                            <Icons.TrashBinTrash size={18} />
                        </Button>
                    )}
                </div>
            )}
        </div>
    );

    return isDraggable && !isNew ? (
        <Draggable draggableId={step.id} index={index}>
            {(provided) => renderCard(provided)}
        </Draggable>
    ) : (
        renderCard()
    );
};

export default KanbanCard;

// function useStep() {
//     throw new Error('Function not implemented.');
// }