import React from 'react';
import Button from '../../../../grapes/atoms/button/Button';
import { useUser } from '../../export';
import { useNavigate } from 'react-router-dom';

const GuideDashboardButton: React.FC = () => {
    const user = useUser();
    const navigate = useNavigate();
    return (
        <>
        {(user.state.data.role === 'Teacher' || user.state.data.role === 'Guide' || user.state.data.role === 'Parent') && (
            <Button 
                variant='primary'
                label="Dashboard" 
                onClick={() => navigate('/guide-dashboard')}
            >
                <p>Dashboard</p>
            </Button>
        )}
        </>
    );
}

export default GuideDashboardButton;
