import { useNavigate, useParams } from 'react-router-dom';
import StepsPanel from '../../../steps/components/Panel';
import GuideHeroCard from './components/GuideHeroCard';
import React, { useEffect, useState } from 'react';
import { API as UserAPI } from "../../api";
import { toastSlice } from '../../../toast/slice';
import ProjectSidebar from './components/ProjectSidebar';
import { Project } from '../../../project/state';
import AdditionalInstructionsInput from './components/AdditionalInstructionsInput';
import { GuideAPI } from '../../guide-api';
import mixpanel from 'mixpanel-browser';
import { useAuth } from '../../../auth/export';

const LearnerOverview: React.FC = () => {
  const { email } = useParams() as { email: string };
  const auth = useAuth();
  const navigate = useNavigate();
  const [learnerInfo, setLearnerInfo] = useState<any>(null);
  const [currentProject, setCurrentProject] = useState<Project | null>(null);
  const currentTask = currentProject?.inputSteps && currentProject.inputSteps.length > 0
    ? currentProject.inputSteps.find(step => step.status === 'DOING') || null
    : null;

  const fetchLearnerInfo = async () => {
    try {
      const response = await UserAPI.getUserInfo({ email });
      logger.log('Learner Info:', response.data);
      setLearnerInfo(response.data);
    } catch (error) {
      logger.error('Error fetching learner info:', error);
      setLearnerInfo(null);
      toastSlice.actions.setToast({
        type: "error",
        message: `Failed to fetch learner information: ${error}`,
        error: null,
        fatal: false
      });
    }
  };

  const submitTeacherPromptForUser = async (prompt: string) => {
    try {
      await GuideAPI.addGenericTeacherPromptToUser({
        userEmail: email,
        teacherPrompt: prompt
      });
      toastSlice.actions.setToast({
        type: "success",
        message: `Additional instructions for ${learnerInfo?.name || 'the Learner'} added successfully.`,
        error: null,
        fatal: false
      });
      if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.track('Guide adding prompt to learner', { learnerEmail: email, guideEmail: auth.state.data.email, prompt: prompt });
      }
    } catch (error) {
      console.error('Error adding generic teacher prompt:', error);
      logger.error('Error adding generic teacher prompt:', error);
      toastSlice.actions.setToast({
        type: "error",
        message: `Failed to add additional instructions for ${learnerInfo?.name || 'the Learner'}.`,
        error: null,
        fatal: false
      });
    }
  };

  const submitTeacherPromptForUserProject = async (prompt: string) => {
    try {
      await GuideAPI.addSpecificTeacherPromptToUser({
        threadId: currentProject!.threadId!,
        teacherPrompt: prompt
      });
      toastSlice.actions.setToast({
        type: "success",
        message: `Additional instructions for ${learnerInfo?.name || 'the Learner'} project added successfully.`,
        error: null,
        fatal: false
      });
      if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.track('Guide adding prompt to Learner Project', { learnerEmail: email, guideEmail: auth.state.data.email, projectTitle: currentProject?.title, prompt: prompt });
      }
    } catch (error) {
      console.error('Error adding specific teacher prompt:', error);
      logger.error('Error adding specific teacher prompt:', error);
      toastSlice.actions.setToast({
        type: "error",
        message: `Failed to add additional instructions for ${learnerInfo?.name || 'the Learner'} project.`,
        error: null,
        fatal: false
      });
    }
  };

  useEffect(() => {
    fetchLearnerInfo();
  }, [email]);

  useEffect(() => {
    if (currentProject) {
      logger.log('Current selected project:', currentProject);
    }
  }, [currentProject]);

  return (
    <div className="flex flex-col h-screen min-h-screen w-full">
      {/* Sidebar */}
      <div>
        <GuideHeroCard 
          heroText={`${learnerInfo?.name || 'Learner'}'s Projects`}
          email={learnerInfo?.email}
          buttonInfo={{
            onClick: () => navigate("/guide-dashboard"),
            text: "Back to all learners"
          }} 
          isLearnerView={true}
          learnerInstructions={{
            instructionTitle: `What do you want PathFinder to focus on with ${learnerInfo?.name || 'the Learner'}?`,
            instructionDescription: `Add any additional information that you want Pathfinder to know about when supporting ${learnerInfo?.name || 'the Learner'}.`,
            placeholderText: `I want you to ${learnerInfo?.name || 'the Learner'} to focus on...`,
            currentInstructions: learnerInfo?.teacherPrompt || '',
            onSubmit: submitTeacherPromptForUser
          }}
        />
      </div>
      <div className='flex h-full w-full'>
        <ProjectSidebar currentProject={currentProject} setCurrentProject={setCurrentProject} />
        <div className="flex-grow p-4">
          {/* Main content */}
          <div className="flex-1 flex">
            {/* Left half */}
            <div className="w-1/2 p-4 flex flex-col space-y-4">
              {!currentProject && (
                <h1 className="font-bold">Project Overview</h1>
              )}
              {currentProject ? (
                <div className="flex flex-col space-y-10">
                  <div className="flex flex-col space-y-2">
                    <h1 className="font-semibold">{currentProject.title}</h1>
                    <p className="text-gray-600">{currentProject.description}</p>
                  </div>
                  <AdditionalInstructionsInput
                    instructionTitle={`What do you want PathFinder to focus on with ?`}
                    instructionDescription={`Add any additional information that you want Pathfinder to know about when supporting ${learnerInfo?.name || 'the Learner'}.`}
                    placeholderText={`I want you to help ${learnerInfo?.name || 'the Learner'} focus on...`}
                    currentInstructions={currentProject?.guidePrompt || ''}
                    onSubmit={submitTeacherPromptForUserProject}
                  />
                  {currentProject.summary && (
                    <div className="flex flex-col space-y-2">
                      <h2 className="font-semibold">Learning Journey Summary</h2>
                      <p className="text-gray-600">{currentProject.summary}</p>
                    </div>
                  )}
                </div>
              ) : (
                <p className="text-blue-500 mb-4">Please select a project from the left sidebar to view details.</p>
              )}
            </div>
            {currentProject?.inputSteps && currentProject?.inputSteps.length > 0 ? (
            <div className="w-1/2 p-4 flex flex-col space-y-4">
                <h1 className="font-bold">Progress</h1>
                {currentTask && <div className="bg-yellow-100 px-4 py-2 rounded max-w-[30vw] overflow-hidden whitespace-nowrap text-ellipsis flex-shrink-0">
                    <p className='font-bold truncate'>{`Current Task: `}<span className='font-normal truncate'>{`${currentTask!.title}`}</span></p>
                </div>}
                <div className="w-full bg-gray-50 mt-2 p-4">
                  <StepsPanel inputSteps={currentProject?.inputSteps} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnerOverview;