import React from 'react';
import { useInspirations } from '../export';
import PanelHeader from '../../../panel/components/PanelHeader';
import { styles } from '../../../../../styling/styles';
import { ProjectInspiration } from '../state';

const InspirationPanel: React.FC = () => {
    const { inspirations, actions, highlight, thunks, loading } = useInspirations()

    const getRandomTilt = (index: number) => {
        return index % 2 < 0.5 ? "-rotate-[1.5deg]" : "rotate-[1.5deg]";
    };

    const handleChooseInspiration = (inspiration: ProjectInspiration) => {
        thunks.chooseInspirationThunk(inspiration)
    }

    return (
        <div className="p-4 h-full flex flex-col">
            <PanelHeader />
            <div className='flex flex-col items-center justify-center h-full space-y-10'>
                {loading ? (
                    // Loading skeletons
                    [...Array(3)].map((_, index) => (
                        <div
                            key={index}
                            className={`bg-white transform ${getRandomTilt(index)} ${styles.panelCard} flex flex-row items-end p-4 animate-pulse`}
                        >
                            <div className="w-1/3 mr-4">
                                <div className="w-full h-0 pb-[66.67%] bg-gray-200 rounded-sm"></div>
                            </div>
                            <div className="w-2/3">
                                <div className="h-6 bg-gray-200 rounded w-3/4 mb-2"></div>
                                <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
                                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                            </div>
                        </div>
                    ))
                ) : (
                    // Actual content
                    inspirations.map((inspiration, index) => (
                        <button
                            key={inspiration.image.url}
                            onClick={() => handleChooseInspiration(inspiration)}
                            onMouseEnter={() => actions.setHighlighted(index)}
                            onMouseLeave={() => actions.setHighlighted(-1)}
                            className={`${index === highlight ? `${styles.panelCardHighlighted}` : `${styles.panelCard} ${getRandomTilt(index)}`} flex flex-row items-center p-4`}
                        >
                            <div className={`${index === highlight ? "w-[80%]] mr-6" : "w-1/3 mr-4"}  transition-all duration-200 ease-out`}>
                                <img
                                    src={inspiration.image.url}
                                    alt={inspiration.title}
                                    className="w-full h-auto aspect-[3/2] object-cover rounded-sm bg-gray-300"
                                />
                            </div>
                            <div className='flex flex-col items-start text-left'>
                                {highlight === index ? (
                                    <h2 className="mb-2 text-3xl">{inspiration.title}</h2>
                                ) : (
                                    <h5 className="mb-2">{inspiration.title}</h5>
                                )}
                                <p>{inspiration.description}</p>
                            </div>
                        </button>
                    ))
                )}
            </div>
        </div>
    );
};

export default InspirationPanel;