import { createSlice } from "@reduxjs/toolkit";
import { CartesiaState, initState } from "./state";
import { Voice, Model } from "./types";
import { Action } from "../index"

export const cartesiaSlice = createSlice({
    initialState: initState,
    name: "cartesia",
    reducers: {
        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },
        setError(state, action: Action<string | null>) {
            return {
                ...state,
                error: action.payload,
            };
        },
        setModelId(state, action: Action<string>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    modelId: action.payload,
                },
            };
        },
        setVoice(state, action: Action<{ mode: string; id: string }>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    voice: action.payload,
                },
            };
        },
        setAudioData(state, action: Action<any>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    audioData: action.payload,
                },
            };
        },
        reset(state) {
            return initState;
        },
    },
});

export const { setLoading, setError, setModelId, setVoice, setAudioData, reset } = cartesiaSlice.actions;
export default cartesiaSlice.reducer;