import React from "react";
import Toggle from "react-toggle";
import { useSession } from "../../session/export";
import "./toggle.css";

const SocraticToggle: React.FC = () => {
    const session = useSession();

    return (
        <label>
            <Toggle
                defaultChecked={session.isSocratic}
                icons={false}
                onChange={(e) => {session.actions.setIsSocratic(e.target.checked)}} />
        </label>
    );
};

export default SocraticToggle;