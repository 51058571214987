export type AuthState = {
    data: {
        awsID: string;
        username: string;
        email: string;
    };
    initialized: boolean;
    loading: boolean;
    cancelled: boolean;
};

export const initState: AuthState = {
    data: {
        awsID: "",
        username: "",
        email: "",
    },
    initialized: false,
    loading: false,
    cancelled: false,
};
