import axios from "axios";
import { FIREBASE_BASE_URL } from "../../utils";
import { ResourcePage } from "./state";

export namespace API {
    export const saveResourcePage = async (props: { userEmail: string, threadId: string, resourcePageNumber: number, resourcePage: ResourcePage }) => {
        const response = await axios.post(
            `${FIREBASE_BASE_URL}/addResourcePageToUser`,
            {
                userEmail: props.userEmail.toLowerCase(),
                threadId: props.threadId,
                resourcePageNumber: props.resourcePageNumber,
                title: props.resourcePage.title,
                description: props.resourcePage.description,
                mainResource: props.resourcePage.mainResource,
                extraResources: props.resourcePage.extraResources
            }
        );
        return response
    }
}