import React from 'react';
import * as Icons from 'solar-icon-set'
import { useAssistant } from '../../export';
import '../../../../grapes/atoms/button/button.css'
import Button from '../../../../grapes/atoms/button/Button';
import mixpanel from 'mixpanel-browser';

interface SuggestionProps {
    suggestion: string;
}

const Suggestion: React.FC<SuggestionProps> = ({ suggestion }) => {
    const assistant = useAssistant();

    const handleClick = () => {
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Suggestion Click', { name: suggestion });
        }
        assistant.thunks.sendMessage(suggestion);
    };

    return (
        <div className="flex flex-row justify-between w-full">
            <Button
                variant='secondary'
                className='w-full justify-between px-4'
                onClick={handleClick}
            >
                <p className='button text-ellipsis overflow-hidden line-clamp-1'>{suggestion}</p>
                <Icons.ArrowRightUp className="ml-2" size={24} />
            </Button>
        </div>
    );
};

export default Suggestion;
