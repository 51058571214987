import { createSlice } from "@reduxjs/toolkit";
import { Option, initState } from "./state";
import { Action } from "..";

export const optionsSlice = createSlice({
    initialState: initState,
    name: "options",
    reducers: {

        setOptions(state, action: Action<Option[]>) {
            return {
                ...state,
                options: action.payload,
            }
        },

        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload
            }
        },

        reset(state) {
            return {
                ...state,
                ...initState
            }
        }

    }
});