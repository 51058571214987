import { AssistantThunks } from "../../assistant/thunks";
import { panelSlice } from "../../panel/slice";
import { toastSlice } from "../../toast/slice";
import { Thunk } from "../../utils";
import { widgetSlice } from "../../widget/slice";
import { Project } from "../state";
import { ProjectThunks } from "../thunks";
import { projectInspirationsSlice } from "./slice";
import { ProjectInspiration } from "./state";

export namespace ProjectInspirationsThunks {

    export const addInspirations: Thunk<any> = (args: any) => async (dispatch, getState) => {
        try {
            logger.log("[PROJECT INSPIRATION THUNK] 🔵 Add inspiration operation started");
            // dispatch(panelSlice.actions.setState('inspirations'));
            // dispatch(widgetSlice.actions.setState('inspirations'));
            const parsedArgs = JSON.parse(args) as { inspirations: ProjectInspiration[] } | undefined;
            if (parsedArgs && 'inspirations' in parsedArgs) {
                const { inspirations } = parsedArgs;
                logger.log(`Found ${inspirations.length} project inspirations`);
                dispatch(projectInspirationsSlice.actions.setInspirations(inspirations));
                dispatch(projectInspirationsSlice.actions.setLoading(false));
            } else {
                dispatch(projectInspirationsSlice.actions.setLoading(true));
            }
        } catch (error: any) {
            logger.log("[PROJECT INSPIRATION THUNK] 🔴 Error adding inspirations", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to add the resource page.",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
            dispatch(panelSlice.actions.reset());
            dispatch(widgetSlice.actions.reset());
        } finally {
            logger.log("[PROJECT INSPIRATION THUNK] 🟢 Add inspiration complete");
        }
    }

    export const chooseInspirationThunk: Thunk<any> = (inspiration: ProjectInspiration) => async (dispatch, getState) => {
        logger.log("[PROJECT THUNK] 🔵 Choosing inspiration");

        try {
            dispatch(AssistantThunks.sendMessage(`I like the ${inspiration.title}`))
            console.log("AMAAN", inspiration)
            dispatch(ProjectThunks.setProjectThunk(inspiration as Project))
            dispatch(panelSlice.actions.reset())
            dispatch(widgetSlice.actions.reset())
        } catch (error: any) {
            logger.error("[PROJECT THUNK] 🔴 Error choosing inspirations", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to save inspirations",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            logger.log("[PROJECT THUNK] 🟢 Choose inspirations completed");
        }
    };

}