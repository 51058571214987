import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AddLearnersForm from './AddLearnersForm';

interface AddLearnersModalProps {
    isOpen: boolean;
    onClose: () => void;
    userEmail: string;
}

const AddLearnersModal: React.FC<AddLearnersModalProps> = ({
    isOpen,
    onClose,
    userEmail,
}) => {
    if (!isOpen) return null;
    return (
        <div
            className="fixed top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-70 flex items-center justify-center z-50"
            onClick={onClose}
        >
            <div onClick={(e) => e.stopPropagation()} className="w-full max-w-[50vw] mx-auto">
                <div className="bg-white p-8 rounded-md shadow-lg max-h-[calc(100vh-100px)] overflow-y-scroll relative">
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-700"
                        aria-label="Close modal"
                    >
                        <FontAwesomeIcon icon={faTimes} size="lg" />
                    </button>
                    <AddLearnersForm
                        userEmail={userEmail}
                        onClose={onClose}
                    />
                </div>
            </div>
        </div>
    );
};

export default AddLearnersModal;