import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../../index";
import { ProjectInspiration, initState } from "./state";

export const projectInspirationsSlice = createSlice({
    initialState: initState,
    name: "projectInspirations",
    reducers: {
        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },

        setHighlighted(state, action: Action<number>) {
            return {
                ...state,
                highlighted: action.payload,
            };
        },

        setInspirations(state, action: Action<ProjectInspiration[]>) {
            return {
                ...state,
                inspirations: action.payload
            };
        },

        reset() {
            return {
                ...initState
            }
        },
    },
});
