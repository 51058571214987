export type ProjectImagesState = {
    images: Image[]
    loading: boolean;
};

export type Image = {
    url: string
}

export const initState: ProjectImagesState = {
    images: [],
    loading: false,
};
