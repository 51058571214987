import React, { useEffect, useState } from 'react';
import * as Icons from 'solar-icon-set'
import { styles } from '../../../../styling/styles';
import ImagePanel from '../../project/images/components/ImagePanel';
import ResourcePanel from '../../project/resources/components/Panel';
import InspirationPanel from '../../project/inspirations/components/Panel';
import SummaryPanel from '../../project/summary/components/SummaryPanel';
import StepsPanel from '../../steps/components/Panel';
import ProgressUploadWidget from '../../project/progress-upload/components/Widget';
import { useModal } from '../export';
import ModalHeader from './ModalHeader';

export const Modal = () => {
    const { state } = useModal();
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className={`absolute top-0 left-0 inset-0 z-50 flex items-center justify-center ${state === "none" ? 'invisible' : 'visible'}`}>
            <div className="fixed inset-0 bg-black opacity-50" onClick={() => setIsVisible(false)}></div>
            <div className="bg-white rounded-lg shadow-xl z-10 p-6 max-w-2xl w-full mx-4">
                <div className="flex justify-between items-center mb-4">
                    {/* <h2 className="text-2xl font-bold">Modal Title</h2> */}
                    <ModalHeader />
                </div>
                <div className="mb-6">
                    {state === 'upload' && <ProgressUploadWidget />}
                    {/* Add other conditional renders based on state */}
                </div>
            </div>
        </div>
    );
};