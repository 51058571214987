import axios from "axios";
import { CARTESIA_API_BASE_URL } from "../utils";
import { getCartesiaConfig, isCartesiaInitialized } from '../../../init/cartesiaInit';
import { CartesiaState } from './state';
import { setLoading, setError, setAudioData } from './slice';
import { Dispatch } from 'redux';
import mixpanel from "mixpanel-browser";

export namespace CartesiaAPI {
    const getOptions = (message: string) => {
        if (!isCartesiaInitialized()) {
            throw new Error('Cartesia has not been initialized');
        }
        const config = getCartesiaConfig();
        return {
            method: 'POST',
            headers: {
                "Cartesia-Version": config.version,
                "Content-Type": "application/json",
                "X-API-Key": config.apiKey
            },
            body: JSON.stringify({
                model_id: "sonic-english",
                transcript: message,
                duration: 123,
                voice: {
                    mode: "id",
                    id: "e00d0e4c-a5c8-443f-a8a3-473eb9a62355",
                    __experimental_controls: {
                        speed: "normal",
                        emotion: ["positivity:high", "curiosity"]
                    }
                },
                output_format: {
                    container: "mp3",
                    encoding: "mp3",
                    sample_rate: 48000
                },
                language: "en"
            })
        };
    };
    export const generateSpeech = async (message: string, dispatch: Dispatch) => {
        dispatch(setLoading(true));
        dispatch(setError(null));

        try {
            const options = getOptions(message);
            logger.log("[CARTESIA API] 🔵 Generating speech:", options);
            const response = await fetch(`${CARTESIA_API_BASE_URL}/tts/bytes`, options);

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(`API Error: ${response.status} - ${errorMessage}`);
            }

            const audioData = await response.arrayBuffer();
            dispatch(setAudioData(audioData));
            dispatch(setLoading(false));
            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                mixpanel.track('Cartesia used', { message });
            }
            return audioData;
        } catch (error) {
            logger.error("[CARTESIA API] 🔴 Error generating speech:", error);
            let errorMessage = 'An unknown error occurred while generating speech';
            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data 
                    ? error.response.data
                    : error.message;
            } else if (error instanceof Error) {
                errorMessage = error.message;
            }
            dispatch(setError(errorMessage));
            dispatch(setLoading(false));
            throw error;
        }
    }
}