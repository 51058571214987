import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
import { useMemo } from "react";
import { OptionsThunks } from "./thunks";
import { optionsSlice } from "./slice";

// Create selectors
const selectLoading = (state: RootState) => state.options.loading;
const selectOptions = (state: RootState) => state.options.options;


export const useOptions = () => {
    const dispatch = useDispatch();

    // Use specific selectors instead of selecting the entire state
    const loading = useSelector(selectLoading);
    const options = useSelector(selectOptions);

    // Memoize actions and thunks
    const actions = useMemo(
        () => bindActionCreators(optionsSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(OptionsThunks, dispatch),
        [dispatch]
    );

    return {
        loading,
        options,
        actions,
        thunks
    };
};
