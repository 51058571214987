import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
import { projectSlice } from "./slice";
import { ProjectThunks } from "./thunks";
import { getCurrentUser } from "aws-amplify/auth";
import { useMemo } from "react";

// Create selectors
const selectProjectState = (state: RootState) => state.project.data;
const selectProjectLastMessaged = (state: RootState) => state.project.data.lastMessaged;
const selectProjectLoading = (state: RootState) => state.project.loading;
const selectProjectInit = (state: RootState) => state.project.initialized;

// New hook for lastMessaged
export const useProjectLastMessaged = () => {
    return useSelector(selectProjectLastMessaged);
};

export const useProject = () => {
    const dispatch = useDispatch();

    // Use specific selector instead of selecting the entire state
    const data = useSelector(selectProjectState);
    const loading = useSelector(selectProjectLoading)
    const initialized = useSelector(selectProjectInit)

    // Memoize actions and thunks
    const actions = useMemo(
        () => bindActionCreators(projectSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(ProjectThunks, dispatch),
        [dispatch]
    );

    return { data, loading, initialized, actions, thunks };
};
