
export type ProjectResourcesState = {
    resourcePages: ResourcePage[];
    page: number
    loading: boolean;
};

export type Resource = {
    title: string,
    url: string,
    content: string,
    score: number
    type: "video" | "article"
}

export type Resources = {
    videos: Resource[],
    articles: Resource[]
}

export type ResourcePage = {
    title: string,
    description: string,
    mainResource: Resource | null
    highlighted?: Resource | null
    extraResources: Resources[]
}

export const initState: ProjectResourcesState = {
    resourcePages: [],
    page: -1,
    loading: false,
};
