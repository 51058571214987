import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../export";
import { Learner } from "./components/types";
import { useAuth } from "../../../auth/export";
import { useSession } from "../../../session/export";
import Button from "../../../../grapes/atoms/button/Button";
import DisplayLearners from "./components/DisplayLearners";
import AddLearnersModal from "./components/AddLearnersModal";
import { GuideAPI } from "../../guide-api";
import PageLoadingDiv from "../../../../grapes/atoms/pageLoading";
import GuideHeroCard from "./components/GuideHeroCard";
import mixpanel from "mixpanel-browser";

const GuideDashboard = () => {
    const user = useUser();
    const auth = useAuth();
    const session = useSession();
    const location = useLocation();
    const navigate = useNavigate();
    const [chatSummary, setChatSummary] = useState<string | null>(null);
    const [addingLearner, setAddingLearner] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const checkLearners = async () => {
            try {
                const learners = await GuideAPI.fetchLearners({ email: auth.state.data.email });
                if (!learners || learners.data.length === 0) {
                    setIsModalOpen(true);
                }
            } catch (error) {
                logger.log("Error fetching learners:", error);
            }
        };

        checkLearners();
    }, [auth.state.data.email]);

    const handleSubmit = async (email: string, override?: boolean) => {
        if (process.env.REACT_APP_MIXPANEL_TOKEN) {
            mixpanel.track('Learner Added', { learnerEmail: email, guideEmail: auth.state.data.email });
        }
        setAddingLearner(true);
        setAddingLearner(false);
        GuideAPI.fetchLearners({ email: auth.state.data.email });
        setAddingLearner(false);
    };

    return (
        <>
            <div className={`flex h-full max-h-screen flex-row`}>
                <div className="h-full min-h-screen flex w-full flex-row">
                    {session.loading ? (
                        <div className="h-screen flex flex-row">
                            <PageLoadingDiv />
                        </div>
                    ) : (
                        <div className="flex h-full w-full items-center justify-center overflow-hidden relative">
                            <div className="flex w-full flex-col space-y-8">
                                <div>
                                    <GuideHeroCard 
                                        heroText={`Welcome ${user.state.data.name}`} 
                                        buttonInfo={{
                                            onClick: () => {
                                                navigate("/");
                                                if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                                                    mixpanel.track('Guide using Learner View', { email: auth.state.data.email });
                                                }
                                            },
                                            text: "Go to learner view"
                                        }}
                                        />
                                </div>
                            <div>
                                <div className="flex flex-col w-full sm:flex-row justify-start sm:justify-between items-start sm:items-center space-y-4 sm:space-y-0 px-8">
                                    <h3 className="font-bold w-full text-black dark:text-white">
                                        Your Learners
                                    </h3>
                                    <div className="flex w-full items-center gap-4 justify-end">
                                        <Button
                                            variant="primary"
                                            label="Add Learners"
                                            onClick={() => {
                                                setIsModalOpen(!isModalOpen);
                                            }}
                                        >
                                            <div className="flex flex-row w-full items-center justify-center ml-1 gap-2">
                                                <FontAwesomeIcon icon={faPlus} />
                                                <p className="w-full">Add Learners</p>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                                    <hr className="border-t-2 px-4 mt-8 border-pf-dark-grey dark:border-white"></hr>
                                </div>
                                <DisplayLearners />
                            </div>
                            <AddLearnersModal
                                isOpen={isModalOpen}
                                onClose={() => setIsModalOpen(false)}
                                userEmail={auth.state.data.email}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default GuideDashboard;