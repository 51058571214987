import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { differenceInDays } from 'date-fns';
import { RootState } from '../../utils/init/reduxInit';
import { deserializeDate } from '../../utils/components/ducks/utils';
import BoardPage from '../Board/BoardPage';
import ChatPage from '../Chat/ChatPage';
import Navbar from '../../utils/components/grapes/molecules/navbar';
import { CircularProgress } from '@mui/material';
import { Modal } from '../../utils/components/ducks/modal/components/Modal';
import { useProject, useProjectLastMessaged } from '../../utils/components/ducks/project/export';
import { addDays } from 'date-fns'
import { API } from '../../utils/components/ducks/project/api';
import { useAuth } from '../../utils/components/ducks/auth/export';
import DayNPage from '../DayN/DayNPage';
import LoadingPage from '../Loading/LoadingPage';
import Button from '../../utils/components/grapes/atoms/button/Button';
import { useAssistant } from '../../utils/components/ducks/assistant/export';
import { useSession } from '../../utils/components/ducks/session/export';

const MainPage: React.FC = () => {
    const session = useSession();
    const auth = useAuth();

    const lastMessaged = useProjectLastMessaged()

    const [showDayN, setShowDayN] = useState(false);

    useEffect(() => {
        if (lastMessaged) {
            const lastMessagedDate = deserializeDate(lastMessaged);
            const daysDifference = differenceInDays(new Date(), lastMessagedDate);
            setShowDayN(daysDifference !== 0);
        }
    }, [lastMessaged]);

    const MainPageWrapper = (props: PropsWithChildren) => {
        return (<div className="flex flex-col h-screen w-screen bg-white">
            <Navbar />
            <div className="flex w-full h-full">
                {props.children}
            </div>
        </div>)
    }

    // if (showDayN) {
    //     return (
    //         <MainPageWrapper>
    //             <DayNPage />
    //         </MainPageWrapper>
    //     )
    // }

    return (
        <>
            {session.loading ?
                <LoadingPage label={"Setting Up..."} />
                :
                <>
                    <Modal />
                    <MainPageWrapper>
                        {
                            session.view === 'Board' ? (
                                <BoardPage />
                            ) : (
                                <ChatPage />
                            )
                        }
                    </MainPageWrapper>
                </>
            }
        </>
    );
};

export default MainPage;
