import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../index"
import { PanelState, initState } from "./state";
import { ErrorState } from "../../../types/ErrorState";

export const panelSlice = createSlice({
    initialState: initState,
    name: "panel",
    reducers: {

        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            }
        },

        setState(state, action: Action<PanelState['state']>) {
            return {
                ...state,
                state: action.payload,
            }
        },

        reset(state) {
            return {
                ...state,
                state: initState.state,
                loading: false,
            }
        }

    }
});