import React from 'react';
import * as Icons from 'solar-icon-set';
import { usePanel } from '../export';
import Button from '../../../grapes/atoms/button/Button';
import { useWidget } from '../../widget/export';
import { useResources } from '../../project/resources/export';

type PanelHeaderProps = {
    onBack?: () => void
    onForward?: () => void
    backEnabled?: boolean,
    forwardEnabled?: boolean,
    closeable?: boolean
}

const PanelHeader = ({ onBack, onForward, backEnabled, forwardEnabled, closeable = true }: PanelHeaderProps) => {

    const panel = usePanel()
    const widget = useWidget()
    const resources = useResources()

    return (
        <div className="flex justify-between items-center mb-4">
            <div className='gap-x-2 flex'>
                {onBack &&
                    <Button
                        disabled={!backEnabled}
                        onClick={onBack}
                        variant={'secondary'}
                    >
                        <Icons.ArrowLeft size={24} />
                    </Button>
                }
                {onForward &&
                    <Button
                        disabled={!forwardEnabled}
                        onClick={onForward}
                        variant={'secondary'}
                    >
                        <Icons.ArrowRight size={24} />
                    </Button>
                }
            </div>
            {closeable && (
                <Button variant={'secondary'} onClick={() => {
                    panel.actions.setState('none')
                    widget.actions.setState('none')
                    resources.actions.setMainResource(null)
                }}>
                    <Icons.CloseCircle iconStyle='Bold' size={24} />
                </Button>
            )}
        </div>
    );
};

export default PanelHeader;

