import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../index"
import { ModalState, initState } from "./state";
import { ErrorState } from "../../../types/ErrorState";
import { init } from "mixpanel-browser";

export const modalSlice = createSlice({
    initialState: initState,
    name: "modal",
    reducers: {

        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            }
        },

        setState(state, action: Action<ModalState['state']>) {
            return {
                ...state,
                state: action.payload,
            }
        },

        reset() {
            return {
                ...initState
            }
        }

    }
});