import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../../index";
import { Resource, ResourcePage, initState } from "./state";

export const projectResourcesSlice = createSlice({
    initialState: initState,
    name: "projectResources",
    reducers: {
        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            };
        },

        // NOTE: this set's the page in the way that they came in.
        // e.g. If you have 10 pages, and set page 2, it'll set page as 8.
        setPage(state, action: Action<number>) {
            return {
                ...state,
                page: state.resourcePages.length - action.payload,
            };
        },

        setHighlighted(state, action: Action<Resource | null>) {
            const updatedPages = [...state.resourcePages];
            updatedPages[state.page] = {
                ...updatedPages[state.page],
                highlighted: action.payload
            };
            return {
                ...state,
                resourcePages: updatedPages
            };
        },

        previousPage(state) {
            if (state.page < state.resourcePages.length - 1) {
                return {
                    ...state,
                    page: state.page + 1,
                };
            }
            return state;
        },

        nextPage(state) {
            if (state.page > 0) {
                return {
                    ...state,
                    page: state.page - 1,
                };
            }
            return state;
        },

        addResourcePage(state) {
            const emptyPage: ResourcePage = {
                title: "",
                description: "",
                mainResource: null,
                extraResources: []
            };
            return {
                ...state,
                page: 0,
                resourcePages: [emptyPage, ...state.resourcePages],
            };
        },

        addResources(state, action: Action<ResourcePage>) {
            return {
                ...state,
                page: 0,
                resourcePages: [
                    // ...state.resourcePages.slice(0, 1),
                    action.payload,
                    ...state.resourcePages.slice(1)
                ],
            };
        },

        setMainResource(state, action: Action<Resource | null>) {
            const updatedPages = [...state.resourcePages];
            updatedPages[state.page] = {
                ...updatedPages[state.page],
                mainResource: action.payload
            };
            return {
                ...state,
                resourcePages: updatedPages
            };
            return state;
        },

        reset(state) {
            return {
                ...initState
            };
        },
    },
});
