import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
import { cartesiaSlice } from "./slice";
import { CartesiaThunks } from "./thunks";
import { useMemo } from "react";
import { CartesiaState } from "./state";

export const useCartesia = () => {
    const dispatch = useDispatch();

    const selectCartesiaState = (state: RootState) => state.cartesia;

    const cartesiaState = useSelector(selectCartesiaState);
    const { loading, data, error, voices, models } = cartesiaState;

    const actions = useMemo(
        () => bindActionCreators(cartesiaSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(CartesiaThunks, dispatch),
        [dispatch]
    );

    return {
        state: cartesiaState,
        actions,
        thunks,
    };
};