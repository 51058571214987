import { Model, Voice } from "./types";

const predefinedVoices: Voice[] = [
    { id: 'voice1', name: 'Voice 1' },
    { id: 'voice2', name: 'Voice 2' },
    // Add more predefined voices as needed
];

const predefinedModels: Model[] = [
    { id: 'sonic-english', name: 'Sonic English' },
    { id: 'sonic-multilingual', name: 'Sonic Multilingual' },
    // Add more predefined models as needed
];

export type CartesiaState = {
    data: {
        audioData: any;
        modelId: string;
        voice: {
            mode: string;
            id: string;
        };
    };
    loading: boolean;
    error: string | null;
    voices: Voice[];
    models: Model[];
};

export const initState: CartesiaState = {
    data: {
        audioData: null,
        modelId: "sonic-english",
        voice: {
            mode: "id",
            id: "",
        },
    },
    loading: false,
    error: null,
    voices: predefinedVoices,
    models: predefinedModels,
};