import React, { PropsWithChildren, ButtonHTMLAttributes } from 'react';
import '../../../grapes/atoms/button/button.css'

type WidgetOptionProps = {
    selected?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

const WidgetOption = ({ children, className, selected, ...props }: WidgetOptionProps) => {
    return (
        <button
            className={`${className || ''} ${selected ? "bg-sky-50 text-sky-500" : "button--tertiary"}  border-b-2 w-full block p-2 text-left`}
            onClick={() => props.onClick}
            {...props}
        >
            {children}
        </button>
    )
}

export default WidgetOption