import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../init/reduxInit';
import { Resource } from '../state';
import { useResources } from '../export';
import WidgetOption from '../../../widget/components/WidgetOption';
import * as Icons from 'solar-icon-set'
import { randomUUID } from 'crypto';
import { ProjectResourcesThunks } from '../thunks';

const ResourceWidget: React.FC = () => {
    const resources = useResources()

    if (resources.resourcePages.length === 0) {
        return <div className="p-4">No resources available.</div>;
    }

    const renderResourceOption = (resource: Resource, index: number, type: 'video' | 'article') => (
        <WidgetOption
            key={`${type}-${index}`}
            selected={resource.url === resources.currentPage.mainResource?.url}
            onClick={() => {
                resources.actions.setMainResource(resource)
                resources.thunks.saveResourcePageThunk()
            }}
            onMouseEnter={() => {
                resources.actions.setHighlighted(resource);
            }}
            onMouseLeave={() => resources.actions.setHighlighted(null)}
        >
            <div className="flex flex-row justify-between">
                <p className='text-ellipsis overflow-hidden line-clamp-1'>{resource.title}</p>
                <Icons.ArrowRightUp className="ml-2" size={24} />
            </div>
        </WidgetOption>
    );

    return (
        <div className="p-2">
            {resources.currentPage.extraResources.map((resourceGroup, groupIndex) => (
                <div key={groupIndex}>
                    {resourceGroup.videos.map((video, index) => renderResourceOption(video, index, 'video'))}
                    {resourceGroup.articles.map((article, index) => renderResourceOption(article, index, 'article'))}
                </div>
            ))}
        </div>
    );
};

export default ResourceWidget;