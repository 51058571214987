import { Amplify } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { PropsWithChildren, useEffect } from "react";
import { useAuth } from "../components/ducks/auth/export";
import LoadingPage from "../../pages/Loading/LoadingPage";

export const AwsAmplifyInit = (props: PropsWithChildren) => {
    Amplify.configure(awsconfig);
    const auth = useAuth();

    useEffect(() => {
        if (!auth.state.initialized) auth.thunks.autoSignInThunk();
    }, [auth.state.initialized]);

    if (auth.state.initialized) {
        return <>{props.children}</>;
    } else {
        return <LoadingPage label={"Signing in..."} />
    }
};