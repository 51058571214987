import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../init/reduxInit';
import { Image } from '../state';
import { useImages } from '../export';
import PanelHeader from '../../../panel/components/PanelHeader';
import Img from '../../../../grapes/atoms/image/Image'

const ImagePanel: React.FC = () => {
    const images = useImages()

    const getRandomTilt = () => {
        return Math.random() < 0.5 ? "-16" : "8";
    };

    const getRandomY = () => {
        return Math.random() < 0.5 ? "-5" : "5";
    };

    return (
        <div className="p-4">
            <PanelHeader />
            {images.loading && (
                <div className="flex flex-wrap justify-between gap-y-16 p-8">
                    {[...Array(3)].map((_, index) => {
                        const tiltClass = index % 2 > 0.5 ? "-rotate-5" : "rotate-5";
                        const size = "w-[calc(33%-64px)]"
                        return (
                            <div
                                key={index}
                                className={`my-4 mx-4 transform ${size} ${tiltClass} bg-gray-200 animate-pulse`}
                                style={{ aspectRatio: '1 / 1' }}
                            ></div>
                        );
                    })}
                </div>
            )}
            <div className="flex flex-wrap">
                {images.images.map((image: Image, index: number) => {
                    const tiltClass = Math.random() > 0.5 ? "-rotate-5" : "rotate-5";
                    const size = index % 5 < 3 ? "w-[calc(33%-32px)]" : "w-[calc(50%-32px)]"
                    return (
                        <Img key={index + image.url}
                            src={image.url}
                            alt={`Image ${index + 1}`}
                            className={`my-4 mx-4 transform ${size} ${tiltClass} bg-gray-500`}
                        />
                    );
                })}
            </div>

        </div>
    );
};

export default ImagePanel;