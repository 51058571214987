import React, { act, useEffect, useState } from 'react';
import { useWidget } from '../export';
import ResourceWidget from '../../project/resources/components/Widget';
import { useSession } from '../../session/export';
import axios from 'axios';
import { FIREBASE_BASE_URL } from '../../utils';
import ProgressUploadWidget from '../../project/progress-upload/components/Widget';
import InspirationWidget from '../../project/inspirations/components/Widget';
import { useAssistant } from '../../assistant/export';
import OptionsWidget from '../../options/components/Widget';
import StepsWidget from '../../steps/components/Widget';
import { useSteps } from '../../steps/export';
import * as Icons from 'solar-icon-set';
import FileUploadWidget from '../../assistant/components/chat/FileUploadWidget';

export const Widget = () => {
    const { state } = useWidget();
    const session = useSession();
    const steps = useSteps()
    const [showChildren, setShowChildren] = useState(true);

    useEffect(() => {
        setShowChildren(true);
    }, [state]);

    const WidgetContent = ({ children }: { children?: React.ReactNode }) => (
        <div className='flex w-full justify-center'>
            <div className={`border-gray-300 border-4 border-b-0 border-t-8 rounded !rounded-b-none w-11/12 transition-all duration-300 ease-in-out ${children ? "p-4 pt-2 pb-8" : "p-0"} pb-4 translate-y-[16px]`}>
                <div className={`flex flex-col transition-all duration-300 ease-in-out ${children ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
                {children && (
                    <button className='pt-0 mt-0' onClick={() => setShowChildren(!showChildren)}>
                        {showChildren ? <Icons.AltArrowDown size={24} /> : <Icons.AltArrowUp size={24} />}
                    </button>
                )}
                    <div className={`flex flex-col space-y-2 transition-all duration-300 ease-in-out overflow-hidden ${showChildren ? 'max-h-[500px]' : 'max-h-0'}`}>
                        {showChildren ? children : null}
                    </div>
                </div>
            </div>
        </div>
    );

    switch (state) {
        case "resources":
            return (
                <WidgetContent>
                    <ResourceWidget />
                </WidgetContent>
            );
        case "inspirations":
            return (
                <WidgetContent>
                    <InspirationWidget />
                </WidgetContent>
            );
        case "upload":
            return (
                <WidgetContent>
                    <ProgressUploadWidget />
                </WidgetContent>
            );
        case "options":
            return (
                <WidgetContent>
                    <OptionsWidget />
                </WidgetContent>
            );
        case "steps":
            return (
                <WidgetContent>
                    <StepsWidget />
                </WidgetContent>
            );
        case "file":
            return (
                <WidgetContent>
                    <FileUploadWidget />
                </WidgetContent>
            );
        default:
            return (
                <WidgetContent />
            );
    }
};
