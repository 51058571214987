import React, { useState, useCallback } from 'react';
import { useSteps } from '../export';
import * as Icons from 'solar-icon-set'
import { Step } from '../state';
import Button from '../../../grapes/atoms/button/Button';
import { useAssistant } from '../../assistant/export';
import { useWidget } from '../../widget/export';
import WidgetOption from '../../widget/components/WidgetOption';
import { useSession } from '../../session/export';

const StepsWidget: React.FC = () => {
    const { thunks, steps, actions } = useSteps();
    const assistant = useAssistant()
    const session = useSession()
    const widget = useWidget()
    const [stepText, setStepText] = useState('');
    const [showStepsForm, setShowStepsForm] = useState(true);

    return (
        <div className='flex px-4 pt-2 pb-4 space-y-4 w-full h-full justify-center'>
            <form
                className="flex flex-row gap-x-6 w-full relative bg-white"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (stepText.trim()) {
                        thunks.addStep(stepText.trim());
                        assistant.thunks.sendMessage(`I've added the step: ${stepText.trim()} to my board`);
                        setStepText('');
                    }
                }}
            >
                <textarea
                    className="text-xl font-bold w-full h-36 p-2 border-2 bg-white ring-2 ring-gray-600 resize-none rounded-sm focus:border-2 focus:ring-2 focus:ring-gray-500 focus:border-gray-500"
                    placeholder="Enter your step here..."
                    value={stepText}
                    onChange={(e) => setStepText(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            e.currentTarget.form?.requestSubmit();
                        }
                    }}
                />
                <Button
                    type="submit"
                    className="mt-4 mr-4 mb-2 ml-2 transition-transform hover:-translate-y-4 hover:translate-x-4 aspect-square"
                    variant="primary"
                >
                    <Icons.ArrowRightUp size={48} />
                </Button>
            </form>
            {/* <WidgetOption
                onClick={() => session.actions.setView('Board')}
            >
                <div className="flex flex-row justify-between">
                    <p className='text-ellipsis overflow-hidden line-clamp-1'>{"See Board"}</p>
                    <Icons.ChecklistMinimalistic className="ml-2" size={24} />
                </div>
            </WidgetOption> */}
        </div>
    );
};

export default StepsWidget;