import { Thunk } from "../utils";
import { signUp, signIn, resetPassword, signOut, ResetPasswordOutput, confirmResetPassword } from "aws-amplify/auth";
import { authSlice } from "./slice";
import { getUserInfo } from "./export";
import { userSlice } from "../user/slice";
import { UserThunks } from "../user/thunks";
import { toastSlice } from "../toast/slice";
import { sessionSlice } from "../session/slice";
import { Amplify } from "aws-amplify";
import { API } from "../project/api";
import { SessionThunks } from "../session/thunks";
import mixpanel from "mixpanel-browser";
import { ProjectThunks } from "../project/thunks";

export namespace AuthThunks {
    /**
     * Example thunk
     *
     * @returns lorem ipsum
     */
    export const signUpThunk: Thunk<any> =
        (props: {
            name: any;
            role: any;
            username: string;
            password: string
        }) =>
            async (dispatch, getState) => {
                logger.log("[AUTH THUNK] 🎬 sign up");

                try {
                    // Call set loading on every duck used in thunk
                    dispatch(authSlice.actions.setLoading(true));
                    // TODO: Add username as an input to the form
                    const response = await signUp({
                        username: props.username,
                        password: props.password,
                    });

                    await dispatch(
                        authSlice.actions.setAll({
                            awsID: response.userId!,
                            username: props.username,
                            email: props.username,
                        })
                    );
                    dispatch(
                        userSlice.actions.setUserInfo({
                            name: props.name,
                            role: 'Guide',
                            Guides: [],
                            Learners: []
                        })
                    )

                    // Call the user creation thunk from the user duck
                    await dispatch(UserThunks.createUserThunk({
                        name: props.name,
                        email: props.username,
                        awsId: response.userId!,
                        role: 'Guide',
                    }));
                    if (getState().auth.data.email) {
                        await API.saveProject({
                            thread: getState().session.data.thread!,
                            userEmail: getState().auth.data.email,
                        });
                    }

                } catch (error: any) {
                    logger.log("[AUTH THUNK] 🔴 sign up:", error);
                } finally {
                    // Call set loading on every duck used in thunk
                    dispatch(authSlice.actions.setLoading(false));
                    logger.log("[AUTH THUNK] 🔚 sign up complete");
                }
            };

    export const signInThunk: Thunk<any> =
    (props: { username: string; password: string }) =>
        async (dispatch, getState) => {
            logger.log("[AUTH THUNK] 🎬 sign in");

            try {
                dispatch(authSlice.actions.setLoading(true));
                const response = await signIn({
                    username: props.username,
                    password: props.password,
                });
                if (response.isSignedIn) {
                    const userInfo = await getUserInfo();
                    await dispatch(
                        authSlice.actions.setAll({
                            awsID: userInfo.userId!,
                            username: props.username,
                            email: userInfo.signInDetails!.loginId!,
                        })
                    );
                    dispatch(UserThunks.getUserInfoThunk());
                    logger.log("[AUTH THUNK] ✅ sign in successful");
                } else {
                    // If not signed in, throw an error
                    throw new Error("Sign in failed");
                }
                if (getState().auth.data.email) {
                    API.saveProject({
                        thread: getState().session.data.thread!,
                        userEmail: getState().auth.data.email,
                    });
                }
            } catch (error: any) {
                logger.log("[AUTH THUNK] 🔴 sign in:", error);
                if (error.message === "There is already a signed in user.") {
                    await dispatch(AuthThunks.signOutThunk());
                    dispatch(signInThunk({ username: props.username, password: props.password }));
                    logger.log("[AUTH THUNK] 🔴 sign in:", error, "but signed out and signed back in");
                } else {
                    throw error;
                }
            } finally {
                dispatch(authSlice.actions.setLoading(false));
                logger.log("[AUTH THUNK] 🔚 sign in complete");
            }
        };

    export const autoSignInThunk: Thunk<void> =
        () => async (dispatch, getState) => {
            logger.log("[AUTH THUNK] 🎬 auto sign in");

            try {
                dispatch(authSlice.actions.setLoading(true));
                try {
                    const userInfo = await getUserInfo();
                    if (userInfo.username) {
                        await dispatch(
                            authSlice.actions.setAll({
                                awsID: userInfo.userId!,
                                username: userInfo.username,
                                email: userInfo.signInDetails!.loginId!
                            })
                        );
                        await dispatch(UserThunks.getUserInfoThunk());
                    }

                } catch (error: any) {
                    if (error.name !== "UserUnAuthenticatedException") {
                        throw error;
                    }
                }
                if (getState().auth.data.email) {
                    await API.saveProject({
                        thread: getState().session.data.thread!,
                        userEmail: getState().auth.data.email,
                    });
                }
            } catch (error: any) {
                logger.log("[AUTH THUNK] 🔴 auto sign in:", error);
            } finally {
                dispatch(authSlice.actions.setLoading(false));
                dispatch(authSlice.actions.setInit(true))
                logger.log("[AUTH THUNK] 🔚 auto sign in complete");
            }
        };

    export const forgotPasswordThunk: Thunk<any> =
        (props: { email: string }) => async (dispatch, getState) => {
            logger.log("[AUTH THUNK] 🎬 forgot password");

            try {
                dispatch(authSlice.actions.setLoading(true));
                const output = await resetPassword({ username: props.email });
                logger.log("[AUTH THUNK] ✅ forgot password request sent");
                handleResetPasswordNextSteps(output);
            } catch (error: any) {
                logger.log("[AUTH THUNK] 🔴 forgot password:", error);
            } finally {
                dispatch(authSlice.actions.setLoading(false));
                logger.log("[AUTH THUNK] 🔚 forgot password complete");
            }
        };

    function handleResetPasswordNextSteps(output: ResetPasswordOutput) {
        const { nextStep } = output;
        switch (nextStep.resetPasswordStep) {
            case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                logger.log(
                    `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
                );
                // Collect the confirmation code from the user and pass to confirmResetPassword.
                break;
            case 'DONE':
                logger.log('Successfully reset password.');
                break;
        }
    }

    export const confirmResetPasswordThunk: Thunk<{ username: string; confirmationCode: string; newPassword: string }> =
        (props: { username: string; confirmationCode: string; newPassword: string }) =>
            async (dispatch, getState) => {
                logger.log("[AUTH THUNK] 🎬 confirm reset password");

                try {
                    dispatch(authSlice.actions.setLoading(true));
                    await confirmResetPassword({
                        username: props.username,
                        confirmationCode: props.confirmationCode,
                        newPassword: props.newPassword
                    });
                    logger.log("[AUTH THUNK] ✅ password reset confirmed successfully");
                } catch (error: any) {
                    logger.log("[AUTH THUNK] 🔴 confirm reset password:", error);
                    dispatch(toastSlice.actions.setToast({
                        type: "error",
                        message: "Failed to reset password. Please try again.",
                        error: error.message || "Unknown error occurred",
                        fatal: false
                    }));
                    return false; // Return false to indicate failure
                } finally {
                    dispatch(authSlice.actions.setLoading(false));
                    logger.log("[AUTH THUNK] 🔚 confirm reset password complete");
                }
            };

    // Sid disabled this because kids wont always have emails to be able to verify
    //   export const confirmSignUpThunk: Thunk<any> =
    //     (props: { username: string; code: string }) =>
    //     async (dispatch, getState) => {
    //       logger.log("[AUTH THUNK] 🎬 confirm sign up");

    //       try {
    //         dispatch(authSlice.actions.setLoading(true));
    //         const response = await confirmSignUp({
    //           username: props.username,
    //           confirmationCode: props.code,
    //         });
    //         logger.log("response:", response);
    //       } catch (error: any) {
    //         logger.log("[AUTH THUNK] 🔴 confirm sign up:", error);
    //         dispatch(authSlice.actions.setError(error));
    //       } finally {
    //         dispatch(authSlice.actions.setLoading(false));
    //         logger.log("[AUTH THUNK] 🔚 confirm sign up complete");
    //       }
    //     };

    export const signOutThunk: Thunk<void> = () => async (dispatch, getState) => {
        logger.log("[AUTH THUNK] 🎬 sign out");

        try {
            dispatch(authSlice.actions.setLoading(true));
            const response = await signOut({ global: true });
            logger.log("[AUTH THUNK] ✅ sign out response:", response);
            if (process.env.REACT_APP_MIXPANEL_TOKEN) {
                mixpanel.track('User Logged Out');
                mixpanel.track('Session');
                mixpanel.track('Session Messages', { count: getState().session.data.messagesCount });
            }
            // await dispatch(SessionThunks.newSession(undefined))
            // await dispatch(ProjectThunks.fetchProjectThunk())
            await dispatch(sessionSlice.actions.reset())

        } catch (error: any) {
            logger.log("[AUTH THUNK] 🔴 sign out:", error);
        } finally {
            dispatch(authSlice.actions.setLoading(false));
            logger.log("[AUTH THUNK] 🔚 sign out complete");
        }
    };

    //   export const ifSignedInThunk: Thunk<any> = () => async (dispatch, getState) => {
    //     logger.log("[AUTH THUNK] 🎬 check if signed in");

    //     try {
    //       dispatch(authSlice.actions.setLoading(true));
    //       const userInfo = await currentAuthenticatedUser();
    //       logger.log("userInfo:", userInfo);
    //       return true;
    //     } catch {
    //       return false;
    //     } finally {
    //       dispatch(authSlice.actions.setLoading(false));
    //       logger.log("[AUTH THUNK] 🔚 check if signed in complete");
    //     }
    //   };

    //   export const getCurrentUserIdThunk: Thunk<any> = () => async (dispatch, getState) => {
    //     logger.log("[AUTH THUNK] 🎬 get current user ID");

    //     try {
    //       dispatch(authSlice.actions.setLoading(true));
    //       let userId = Cookies.get("userId");
    //       if (userId) {
    //         return userId;
    //       }
    //       const userInfo = await currentUserInfo();
    //       if (!userInfo) {
    //         return null;
    //       }
    //       const awsId = userInfo.username;
    //       const ourUserId = await axios.post(
    //         "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getUserIdFromAWSId",
    //         { awsId: awsId }
    //       );
    //       userId = ourUserId.data;
    //       const oneHour = 1 / 24;
    //       const expirationDate = new Date(new Date().getTime() + oneHour * 60 * 60 * 1000);
    //       Cookies.set("userId", userId, { expires: expirationDate });
    //       return userId;
    //     } catch (error: any) {
    //       logger.log("[AUTH THUNK] 🔴 get current user ID:", error);
    //       dispatch(authSlice.actions.setError(error));
    //     } finally {
    //       dispatch(authSlice.actions.setLoading(false));
    //       logger.log("[AUTH THUNK] 🔚 get current user ID complete");
    //     }
    //   };

    //   export const getUserNameByEmailThunk: Thunk<any> =
    //     (props: { email: string }) =>
    //     async (dispatch, getState) => {
    //       logger.log("[AUTH THUNK] 🎬 get user name by email");

    //       try {
    //         dispatch(authSlice.actions.setLoading(true));
    //         const response = await axios.post(
    //           "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getPblUserNameByEmail",
    //           { email: props.email }
    //         );
    //         return response.data.name;
    //       } catch (error: any) {
    //         logger.log("[AUTH THUNK] 🔴 get user name by email:", error);
    //         dispatch(authSlice.actions.setError(error));
    //         return null;
    //       } finally {
    //         dispatch(authSlice.actions.setLoading(false));
    //         logger.log("[AUTH THUNK] 🔚 get user name by email complete");
    //       }
    //     };

    //   export const getUserPageIdByEmailThunk: Thunk<any> =
    //     (props: { email: string }) =>
    //     async (dispatch, getState) => {
    //       logger.log("[AUTH THUNK] 🎬 get user page ID by email");

    //       try {
    //         dispatch(authSlice.actions.setLoading(true));
    //         const response = await axios.post(
    //           "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getPblUserPageIdByEmail",
    //           { email: props.email }
    //         );
    //         return response.data.userPageId;
    //       } catch (error: any) {
    //         logger.log("[AUTH THUNK] 🔴 get user page ID by email:", error);
    //         dispatch(authSlice.actions.setError(error));
    //         return null;
    //       } finally {
    //         dispatch(authSlice.actions.setLoading(false));
    //         logger.log("[AUTH THUNK] 🔚 get user page ID by email complete");
    //       }
    //     };

    //   export const getRoleByEmailThunk: Thunk<any> =
    //     (props: { email: string }) =>
    //     async (dispatch, getState) => {
    //       logger.log("[AUTH THUNK] 🎬 get role by email");

    //       try {
    //         dispatch(authSlice.actions.setLoading(true));
    //         const response = await axios.post(
    //           "https://us-central1-thepathfinderprojectbackendapi.cloudfunctions.net/getPblRoleByEmail",
    //           { email: props.email }
    //         );
    //         return response.data.role;
    //       } catch (error: any) {
    //         logger.log("[AUTH THUNK] 🔴 get role by email:", error);
    //         dispatch(authSlice.actions.setError(error));
    //         return null;
    //       } finally {
    //         dispatch(authSlice.actions.setLoading(false));
    //         logger.log("[AUTH THUNK] 🔚 get role by email complete");
    //       }
    //     };
}