import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../init/reduxInit';
import { ProjectInspiration } from '../state';
import { useInspirations } from '../export';
import WidgetOption from '../../../widget/components/WidgetOption';
import * as Icons from 'solar-icon-set'

const InspirationWidget: React.FC = () => {
    const inspirations = useInspirations()

    if (inspirations.inspirations.length === 0) {
        return <div className="p-4">No inspirations available.</div>;
    }

    const handleChooseInspiration = (inspiration: ProjectInspiration) => {
        inspirations.thunks.chooseInspirationThunk(inspiration)
    }

    return (
        <div className="p-2">
            {inspirations.inspirations.map((inspiration: ProjectInspiration, index: number) => (
                <WidgetOption
                    key={`${inspiration.image.url}`}
                    onClick={(event) => handleChooseInspiration(inspiration)}
                    onMouseEnter={() => inspirations.actions.setHighlighted(index)}
                    onMouseLeave={() => inspirations.actions.setHighlighted(-1)}
                >
                    <div className="flex flex-row justify-between">
                        <p className='text-ellipsis overflow-hidden'>{inspiration.title}</p>
                        <Icons.ArrowRightUp className="ml-2" size={24} />
                    </div>
                </WidgetOption>
            ))}
        </div>
    );
};

export default InspirationWidget;
