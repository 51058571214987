import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action, Run } from "../index";
import { initState, Message } from "./state";

export const assistantSlice = createSlice({
    initialState: initState,
    name: "assistant",
    reducers: {
        setLoading(state, action: PayloadAction<"false" | "streaming" | "true">) {
            state.loading = action.payload;
        },

        createMessage(state, action: Action<Message>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    messages: [
                        ...state.data.messages,
                        {
                            content: {
                                message: action.payload.content.message,
                                ...(action.payload.content.fileUrl ? { fileUrl: action.payload.content.fileUrl } : {}),
                            },
                            role: action.payload.role,
                            ...(action.payload.tool ? { tool: action.payload.tool } : {}),
                            // metadata: action.payload.metadata
                        },
                    ],
                },
                focused: state.data.messages.length
            };
        },

        setMessages(state, action: Action<Message[]>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    messages: action.payload,
                },
            };
        },

        streamMessage(state, action: Action<Message>) {
            const lastIndex = state.data.messages.length - 1;
            return {
                ...state,
                data: {
                    ...state.data,
                    messages: [
                        ...state.data.messages.slice(0, lastIndex),
                        {
                            ...state.data.messages[lastIndex],
                            content: {
                                ...state.data.messages[lastIndex]?.content,
                                message: (state.data.messages[lastIndex]?.content?.message || "") + (action.payload.content?.message || ""),
                                tool: action.payload.tool
                            },
                            role: action.payload.role,
                            ...(action.payload.tool ? { tool: action.payload.tool } : {}),
                        }
                    ],
                },
            }
        },

        setSuggestions(state, action: Action<string[] | null>) {
            return {
                ...state,
                data: {
                    ...state.data,
                },
                suggestions: action.payload,
            };
        },

        clearSuggestions(state) {
            return {
                ...state,
                data: {
                    ...state.data,
                },
                suggestions: initState.suggestions,
            };
        },

        setFile(state, action: Action<File | null>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    file: action.payload,
                },
            };
        },

        setFileUrl(state, action: Action<string>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    fileUrl: action.payload,
                },
            };
        },

        setFileStatus(state, action: Action<"none" | "preparing" | "uploading" | "uploaded" | "error">) {
            return {
                ...state,
                fileStatus: action.payload,
            };
        },

        reset(state) {
            return {
                ...state,
                data: initState.data,
                loading: initState.loading,
                status: initState.status,
            };
        },
    },
});