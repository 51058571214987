import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Label } from "flowbite-react";
import Button from "../../atoms/button/Button";
import emailjs from "@emailjs/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../ducks/user/export";
import { useAuth } from "../../../ducks/auth/export";
import { useAssistant } from "../../../ducks/assistant/export";
import { useProject } from "../../../ducks/project/export";
import { AssistantState } from "../../../ducks/assistant/state";
import { ProjectState } from "../../../ducks/project/state";

interface FormDetails {
    to_name: string;
    from_name: string;
    from_email: string;
    message: string;
    current_state: string;
}

interface SuggestFeatureModalProps {
    isOpen: boolean;
    onClose: () => void;
    preFilledMessage?: string;
}

const SuggestFeatureModal: React.FC<SuggestFeatureModalProps> = ({ isOpen, onClose, preFilledMessage }) => {
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSent, setIsSent] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const form = useRef<HTMLFormElement>(null);
    const user = useUser();
    const auth = useAuth();
    const assistant = useAssistant();
    const project = useProject();
    const preFilledMessageValue = preFilledMessage || "";

    const formSchema = Yup.object().shape({
        to_name: Yup.string().default("Pathfinder Team"),
        from_name: Yup.string().default(user.state.data.name || ""),
        from_email: Yup.string().default(auth.state.data.email || ""),
        message: Yup.string().required("Message is required"),
        current_state: Yup.string().required(JSON.stringify({
            assistant: assistant.data,
            project: project.data
        })),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormDetails>({
        mode: "onSubmit",
        resolver: yupResolver(formSchema),
        defaultValues: {
            to_name: "Pathfinder Team",
            from_name: user.state.data.name || "",
            from_email: auth.state.data.email || "",
            message: preFilledMessageValue,
            current_state: JSON.stringify({
                assistant: assistant.data,
                project: project.data
            })
        },
    });

    const sendEmail = () => {
        setIsSubmitting(true);
        if (form.current) {
            // logger.log(form.current);
            emailjs
                .sendForm(
                    "service_lnffz7b",
                    "template_5oifwxb",
                    form.current,
                    "1mrtCcpYjeR_RckcO"
                )
                .then(
                    (result) => {
                        resetErrorState();
                        setIsSent(true);
                        setIsSubmitting(false);
                        reset();
                    },
                    (error) => {
                        setShowError(true);
                        setErrorMessage(error.text);
                        setIsSubmitting(false);
                    }
                );
        }
    };

    function resetErrorState() {
        setShowError(false);
        setErrorMessage("");
    }

    if (!isOpen) return null;

    return (
        <div
            className="fixed top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-70 flex items-center justify-center z-50"
            onClick={onClose}
        >
            <div onClick={(e) => e.stopPropagation()} className="w-full max-w-[50vw] mx-auto">
                <div className="bg-white p-6 rounded-md shadow-lg">
                    <h2 className="text-2xl font-bold mb-4">Give Feedback / Suggest Feature</h2>
                    <form
                        className="flex flex-col gap-4"
                        ref={form}
                        onSubmit={handleSubmit(sendEmail)}
                    >
                        {showError && (
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <span className="block sm:inline">{errorMessage}</span>
                            </div>
                        )}
                        <div>
                            <div className="mb-2 block">
                            </div>
                            <input type="hidden" {...register("to_name")} value="Pathfinder Team" />
                            <input type="hidden" {...register("from_name")} value={user.state.data.name || ""} />
                            <input type="hidden" {...register("from_email")} value={auth.state.data.email || ""} />
                            <input type="hidden" {...register("current_state")} value={JSON.stringify({
                                assistant: assistant.data,
                                project: project.data
                            })} />
                            <textarea
                                className="w-full border rounded-md p-2.5 text-sm focus:ring-blue-500 focus:border-blue-500"
                                rows={6}
                                placeholder="Tell us what you think! Any issues or ideas?"
                                {...register("message")}
                            ></textarea>
                            {errors.message && (
                                <p className="text-red-500 text-xs italic">{errors.message.message}</p>
                            )}
                        </div>

                        <div className="flex justify-end">
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSent ? (
                                    <>
                                        <FontAwesomeIcon icon={faCheck} className="mr-2" />
                                        Sent, thank you!
                                    </>
                                ) : (
                                    <>
                                        Send
                                        <FontAwesomeIcon icon={faPaperPlane} className="ml-2" />
                                    </>
                                )}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SuggestFeatureModal;