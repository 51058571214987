import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "../../../init/reduxInit";
import { stepsSlice } from "./slice";
import { StepsThunks } from "./thunks";
import { useMemo } from "react";
import { initState } from "./state";

// Create selectors
const selectStepsState = (state: RootState) => state.steps.steps;
const selectLoadingSteps = (state: RootState) => state.steps.loading;

export const useSteps = () => {
    const dispatch = useDispatch();

    // Use specific selector instead of selecting the entire state
    const loading = useSelector(selectLoadingSteps);
    const steps = useSelector(selectStepsState);

    // Memoize actions and thunks
    const actions = useMemo(
        () => bindActionCreators(stepsSlice.actions, dispatch),
        [dispatch]
    );
    const thunks = useMemo(
        () => bindActionCreators(StepsThunks, dispatch),
        [dispatch]
    );

    return { loading, steps, actions, thunks };
};