import { createSlice } from "@reduxjs/toolkit";
import { Action } from "../index"
import { AuthState, initState } from "./state";

export const authSlice = createSlice({
    initialState: initState,
    name: "auth",
    reducers: {

        setLoading(state, action: Action<boolean>) {
            return {
                ...state,
                loading: action.payload,
            }
        },

        setAll(state, action: Action<AuthState['data']>) {
            return {
                ...state,
                data: action.payload,
            }
        },

        setEmail(state, action: Action<string>) {
            return {
                ...state,
                data: {
                    ...state.data,
                    email: action.payload
                }
            }
        },

        setInit(state, action: Action<boolean>) {
            return {
                ...state,
                initialized: action.payload,
            }
        },

        reset(state) {
            return {
                ...initState,
                initialized: true,
            }
        }
    }
});