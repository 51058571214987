export type OptionsState = {
    options: Option[],
    loading: boolean
};

export type Option = {
    title: string
}

export const initState: OptionsState = {
    options: [],
    loading: false
};
