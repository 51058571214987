import { PropsWithChildren, useEffect } from "react";
import { useProject } from "./export";
import React from "react";
import { useResources } from "./resources/export";

const SaveProjectWrapper = (props: PropsWithChildren) => {

    const project = useProject()
    const resources = useResources()

    useEffect(() => {
        if (project.data.title) {
            // project.thunks.saveProjectThunk()
        }
    }, [project.data])

    useEffect(() => {
        if (resources.resourcePages.length > 0 && resources.resourcePages[0].title && resources.resourcePages[0].description && resources.resourcePages[0].extraResources.length > 0) {
            // resources.thunks.saveResourcePageThunk()
        }
    }, [resources.resourcePages])

    return (
        <>
            {props.children}
        </>
    )
}

export default React.memo(SaveProjectWrapper)