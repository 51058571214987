import { panelSlice } from "../../panel/slice";
import { toastSlice } from "../../toast/slice";
import { Thunk } from "../../utils";
import { projectImagesSlice } from "./slice";
import { Image } from "./state";

export namespace ProjectImagesThunks {

    export const addImages: Thunk<any> = (args: any) => async (dispatch, getState) => {
        try {
            logger.log("[PROJECT IMAGES THUNK] 🔵 Add images operation complete");
            // dispatch(panelSlice.actions.setState('images'))
            const images = JSON.parse(args).images
            if (images !== undefined) {
                const mappedImages: Image[] = images.map((url: string) => ({ url }));
                dispatch(projectImagesSlice.actions.addImages(mappedImages))
                dispatch(projectImagesSlice.actions.setLoading(false))
            } else {
                dispatch(projectImagesSlice.actions.setLoading(true))
            }
        } catch (error: any) {
            logger.log("[PROJECT IMAGES THUNK] 🔴 Error adding images", error);
            dispatch(toastSlice.actions.setToast({
                type: "error",
                message: "Failed to add the images.",
                error: error.message || "Unknown error occurred",
                fatal: false
            }));
        } finally {
            logger.log("[ASSISTANT THUNK] 🟢 Add messages complete");
        }
    }

}
