import { useEffect, useState } from 'react';
import KanbanColumn from "../../utils/components/ducks/steps/components/Kanban/KanbanColumn"
import { useSteps } from "../../utils/components/ducks/steps/export";
import Navbar from "../../utils/components/grapes/molecules/navbar"
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import Confetti from 'react-confetti';
import Button from '../../utils/components/grapes/atoms/button/Button';
import * as Icons from 'solar-icon-set'
import { API } from '../../utils/components/ducks/steps/api';
import { useModal } from '../../utils/components/ducks/modal/export';
import { useUpload } from '../../utils/components/ducks/project/progress-upload/export';

const BoardPage = () => {

    const { actions, steps } = useSteps();
    const upload = useUpload()
    const modal = useModal()
    const [showConfetti, setShowConfetti] = useState(false);
    const [recentUpload, setRecentUpload] = useState(false)
    const [isDragging, setIsDragging] = useState(false);

    const onDragStart = () => {
        setIsDragging(true);
    };

    useEffect(() => {
        if (recentUpload) {
            setRecentUpload(false)
            setShowConfetti(true)
            setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
        }
    }, [upload.files])

    const onDragEnd = (result: DropResult) => {
        setIsDragging(false);
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        // Create a map of steps by status
        const stepsByStatus = steps.reduce((acc, step) => {
            if (!acc[step.status]) {
                acc[step.status] = [];
            }
            acc[step.status].push(step);
            return acc;
        }, {} as Record<string, typeof steps>);

        // Find the moved step
        const movedStep = steps.find(step => step.id === draggableId);
        if (!movedStep) return;

        // Remove from source
        stepsByStatus[source.droppableId] = stepsByStatus[source.droppableId].filter(
            step => step.id !== draggableId
        );

        // Add to destination
        const updatedStep = { ...movedStep, status: destination.droppableId as 'TODO' | 'DOING' | 'DONE' };

        if (destination.droppableId === 'DOING') {
            // Move all existing DOING cards to TODO
            stepsByStatus['TODO'] = [
                ...stepsByStatus['TODO'],
                ...stepsByStatus['DOING']?.map(step => ({ ...step, status: 'TODO' as const })) || []
            ];
            // Set DOING to only contain the newly moved card
            stepsByStatus['DOING'] = [updatedStep];
            upload.actions.setStep(updatedStep)
        } else {
            // Initialize the destination array if it doesn't exist
            if (!stepsByStatus[destination.droppableId]) {
                stepsByStatus[destination.droppableId] = [];
            }

            stepsByStatus[destination.droppableId] = [
                ...stepsByStatus[destination.droppableId].slice(0, destination.index),
                updatedStep,
                ...stepsByStatus[destination.droppableId].slice(destination.index)
            ];
            upload.actions.setStep(undefined)
        }

        // Flatten the map back into an array
        const updatedSteps = Object.values(stepsByStatus).flat();

        // Update all steps
        actions.setSteps(updatedSteps);

        if (destination.droppableId === 'DONE') {
            setRecentUpload(true)
            upload.actions.setStep(updatedStep)
            modal.actions.setState('upload')
        }
    };
    return (
        <main
            className="flex h-full w-full"
        >
            {showConfetti && <Confetti />}
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <div className="flex flex-row p-8 h-auto w-full gap-x-8">
                    <KanbanColumn status="TODO" isDraggable isDragging={isDragging} />
                    <KanbanColumn status="DOING" isDraggable isDragging={isDragging} />
                    {(steps.some(step => step.status === 'DOING') || steps.some(step => step.status === 'DONE')) && (
                        <KanbanColumn status="DONE" isDraggable isDragging={isDragging} />
                    )}
                </div>
            </DragDropContext>
            {/* <Button
                variant='primary'
                className="absolute bottom-6 right-6 px-8 py-4"
            >

                <h5>+ Add Task</h5>
            </Button> */}
        </main>
    )
}

export default BoardPage